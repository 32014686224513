import { extractAccount } from '../../helpers/tools';
import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

// resources
// https://docs.kaikas.io/02_api_reference/01_klaytn_provider

const { klaytn } = window;
const { caver } = window;

export default class KaikasService extends BaseWalletService implements WalletService {
  isCaverInstalled = () => Boolean(caver && caver?.klay);

  isKaikasInstalled = () => Boolean(klaytn && klaytn.isKaikas);

  isWalletInstalled = () => Boolean(this.isCaverInstalled() || this.isKaikasInstalled());

  init = async (
    newChainHandler?: (chainId: string | number) => void,
    newNetworkHandler?: (networkId: string | number) => void,
    newAccountHandler?: (newAccounts: string | string[]) => void,
    disconnectHandler?: () => void
  ) => {
    super.init(newChainHandler, newNetworkHandler, newAccountHandler, disconnectHandler);

    if (this.isKaikasInstalled()) {
      klaytn.on('chainChanged', this.handleNewChain);
      klaytn.on('networkChanged', this.handleNewNetwork);
      klaytn.on('accountsChanged', this.handleNewAccounts);

      klaytn.autoRefreshOnNetworkChange = false;
      this.getNetworkAndChainId();
    }
  };

  getNetworkAndChainId = async () => {
    try {
      if (this.isKaikasInstalled()) {
        const networkId = await klaytn.networkVersion;
        this.handleNewNetwork(networkId);
      } else {
        this.handleNewNetwork(8217);
      }

      // handled manually because kaikas supports only one chain currently
      this.handleNewChain(8217);
    } catch (err) {
      this.logError(err);
    }
  };

  connect = async () => {
    try {
      if (this.isKaikasInstalled()) {
        const newAccounts = await klaytn.enable();
        this.handleNewAccounts(extractAccount(newAccounts));
      } else {
        await caver.klay.getAccounts((err: string, accounts: string | string[]) => {
          this.handleNewAccounts(extractAccount(accounts));
        });
      }
    } catch (err) {
      this.logError(err);
    }
  };

  // returns account installed on Kaikas
  getAccounts = async (): Promise<string> => {
    if (this.isKaikasInstalled()) {
      return extractAccount(klaytn.selectedAddress);
    }

    if (this.isCaverInstalled()) {
      let accounts: string | string[] = [];
      await caver.klay.getAccounts((err: string, acc: string | string[]) => {
        accounts = acc;
        this.handleNewAccounts(extractAccount(accounts));
      });

      return extractAccount(accounts);
    }

    return '';
  };

  getDownloadWalletPath = (): string => {
    return 'https://docs.kaikas.io';
  };

  getName = (): string => {
    return 'Kaikas';
  };

  // signs a message with password
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  personalSign = async (message: string, password?: string): Promise<string> => {
    try {
      const from = this.getAccounts();
      const signResult = await caver.klay.sign(message, from);
      return signResult;
    } catch (err) {
      this.logError(err);
      return '';
    }
  };
}
