import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CarouselItem } from '../../models/carousel-item-model';
import ClickableCardComponent from '../clickable-card/clickable-card-component';
import './selected-collections-component.scss';
import wizardImg from '../../assets/images/cheeze-wizard.png';
import { getCarouselsAsync } from '../../services/api-service';

export default function SelectedCollectionsComponent(params: { carousel?: CarouselItem[] }): JSX.Element {
  const initParams = params;

  const [carousel, setCarousel] = useState<CarouselItem[]>(initParams.carousel ? initParams.carousel : []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCarouselsAsync(cancelToken).then((carouselItems) => {
      setCarousel(carouselItems);
    });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <div className="mn-blocks home-collection">
      <div className="mn-block-title">
        <h4>Selected collections</h4>
        <span>
          <Link to="/collections" state={{ preSelectedTab: 'collections' }}>
            View more
          </Link>
        </span>
      </div>
      <Row sm={1} md={2} lg={3} xl={4} className="g-4">
        {carousel &&
          carousel?.map((x) => {
            const title = x.properties.find((f) => f.name === 'name')?.value || 'Collection name';
            const text = x.properties.find((f) => f.name === 'description')?.value || 'Collection description';
            const truncatedText = text.length >= 100 ? `${text.substring(0, 100)}...` : text;
            const image = x.properties.find((f) => f.name === 'imagePath')?.value || wizardImg;
            const id = x.properties.find((f) => f.name === 'id')?.value || 'eSog';
            const link = `/collection/${id}`;
            return (
              <Col className="d-flex align-items-stretch" key={`collection-${id}`}>
                <ClickableCardComponent
                  link={link}
                  image={image}
                  title={title}
                  text={truncatedText}
                  isInternalRouting
                  buttonText="Learn More"
                />
              </Col>
            );
          })}
      </Row>
    </div>
  );
}
