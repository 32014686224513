import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBarsStaggered, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { CTooltip } from '@coreui/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Transaction } from '../../models/transaction-model';
import noImageFound from '../../assets/images/no-image-found.jpg';
import LazyCardImageComponent from '../lazy-card-image/lazy-card-image-component';
import LabelledValueControl from '../../controls/labelled-value/labelled-value-control';
import ShortTextControl from '../../controls/short-text/short-text-control';
import './transaction-detail-component.scss';
import counterpartyImage from '../../assets/images/blockchains/counterparty.png';
import klaytnImage from '../../assets/images/blockchains/klaytn.png';
import ethereumImg from '../../assets/images/blockchains/ethereum.png';
import Blockchain from '../../enums/blockchain-enum';
import { capitalizeFirstLetter, extractContractOrName } from '../../helpers/tools';
import { validateEthTxId, validateXcpTxId } from '../../services/address-validator';
import { getTransactionAsync } from '../../services/api-service';
import TransactionDetailsPlaceholder from '../placeholders/transaction-details-placeholder';

type ResponsibleTransactionHashProps = {
  txId: string;
  size: string;
  numberOfChars: number;
};

function ResponsiveTransactionHash({ txId, size, numberOfChars }: ResponsibleTransactionHashProps): JSX.Element {
  return (
    <div className={size}>
      <LabelledValueControl
        label="Transaction hash:"
        value={<ShortTextControl text={txId} numberOfChars={numberOfChars} />}
        useLineSeparator
      />
    </div>
  );
}

type ActionIconsProps = {
  size: string;
};

function ActionIcons({ size }: ActionIconsProps): JSX.Element {
  return (
    <div className={`action-icons ${size}`}>
      <CTooltip placement="auto" content="Copy page url">
        <span>
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => {
              toast('Page address was copied to your clipboard!');
            }}
          >
            <FontAwesomeIcon icon={faShareNodes as IconProp} />
          </CopyToClipboard>
        </span>
      </CTooltip>
    </div>
  );
}

export default function TransactionDetailsComponent(): JSX.Element {
  const { selectedAddress, txId } = useParams();
  const [details, setDetails] = useState<Transaction | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = (cancelToken: CancelTokenSource) => {
    const isTxId = txId && (validateEthTxId(txId) || validateXcpTxId(txId));
    if (isTxId) {
      getTransactionAsync(txId, cancelToken)
        .then((transactionResponse) => {
          setDetails(transactionResponse.data[0]);
          setLoading(false);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (!details || txId !== details.txId) {
      setLoading(true);
      fetchData(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [details, txId]);

  const timestampDate = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' }).format(
    new Date(parseInt(details ? details.timestamp : '0', 10) * 1000)
  );

  let blockchainImage = noImageFound;

  if (details?.blockchain === Blockchain.Counterparty) {
    blockchainImage = counterpartyImage;
  } else if (details?.blockchain === Blockchain.Klaytn) {
    blockchainImage = klaytnImage;
  } else if (details?.blockchain === Blockchain.Ethereum) {
    blockchainImage = ethereumImg;
  }

  const BlockchainHeight = <span className="badge bg-secondary">{details?.blockHeight}</span>;
  /* if (details.blockchain === Blockchain.Counterparty) {
    BlockchainHeight = (
      <a href={`https://xchain.io/block/${details.blockHeight}`} target="_blank" rel="noreferrer">
        {details.blockHeight}
      </a>
    );
  } else if (details.blockchain === Blockchain.Klaytn) {
    BlockchainHeight = (
      <a href={`https://scope.klaytn.com/block/${details.blockHeight}`} target="_blank" rel="noreferrer">
        {details.blockHeight}
      </a>
    );
  } else if (details.blockchain === Blockchain.Ethereum) {
    BlockchainHeight = (
      <a href={`https://etherscan.io/block/${details.blockHeight}`} target="_blank" rel="noreferrer">
        {details.blockHeight}
      </a>
    );
  } */

  return (
    <div className="transactionDetails">
      {loading ? (
        <TransactionDetailsPlaceholder />
      ) : (
        <>
          <ActionIcons size="sm-down" />
          <Row>
            {details && (
              <>
                <Col xs="12" md="4" lg="4">
                  <LazyCardImageComponent
                    imageUrl={
                      details.orbs?.[0]?.imageUrl?.includes('cryptokitties')
                        ? noImageFound
                        : null ??
                          details.orbs?.[0]?.imageUrl ??
                          details.orbs?.[0]?.imgURL ??
                          details.orbs?.[0]?.fallbackImageUrl ??
                          noImageFound
                    }
                    name={`${details.orbs?.[0]?.collection.name} card`}
                    defaultImageUrl={noImageFound}
                    usePlaceholder
                  />
                  <ActionIcons size="md" />
                </Col>
                <Col xs="12" md="8" lg="8">
                  <h1>
                    <Link to={`/collection/${details.orbs?.[0]?.collection.id}`}>
                      {details.orbs?.[0]?.collection?.name}
                    </Link>
                    <ActionIcons size="lg-up" />
                  </h1>
                  <h5 className="mb-4 text-truncate">{extractContractOrName(details)}</h5>
                  <div className="details-card pb-2">
                    <h4 className="d-flex align-items-center">
                      <FontAwesomeIcon className="me-2" icon={faBarsStaggered as IconProp} />
                      <span>Details</span>
                    </h4>
                    <LabelledValueControl
                      label="Blockchain:"
                      value={
                        <>
                          <img src={blockchainImage} style={{ height: 24, marginRight: 12 }} alt="blockchain" />
                          {capitalizeFirstLetter(details.blockchain)}
                        </>
                      }
                      useLineSeparator
                    />
                    <ResponsiveTransactionHash txId={details.txId} size="xl" numberOfChars={26} />
                    <ResponsiveTransactionHash txId={details.txId} size="lg" numberOfChars={22} />
                    <ResponsiveTransactionHash txId={details.txId} size="md" numberOfChars={20} />
                    <ResponsiveTransactionHash txId={details.txId} size="sm" numberOfChars={12} />
                    <LabelledValueControl label="Block height:" value={BlockchainHeight} useLineSeparator />
                    <LabelledValueControl
                      label="Contract:"
                      value={
                        <>
                          <div className="sm-down">
                            <ShortTextControl text={extractContractOrName(details)} numberOfChars={12} />
                          </div>
                          <div className="md-up">
                            <ShortTextControl
                              text={extractContractOrName(details)}
                              numberOfChars={32}
                              textAlignClass="justify-content-end"
                            />
                          </div>
                        </>
                      }
                      useLineSeparator
                    />
                    <LabelledValueControl
                      label="Token ID:"
                      value={
                        details?.contract?.token?.tokenId ? (
                          <span className="badge bg-secondary">#{details?.contract?.token?.tokenId ?? ''}</span>
                        ) : (
                          '-'
                        )
                      }
                      useLineSeparator
                    />
                    <LabelledValueControl label="Timestamp:" value={timestampDate} useLineSeparator />
                    <LabelledValueControl
                      label="From:"
                      value={
                        <>
                          <div className="sm-down">
                            <Link to={`/wallet/${details.source}`}>
                              <ShortTextControl text={details.source} numberOfChars={12} />
                            </Link>
                          </div>
                          <div className="md-up">
                            <Link to={`/wallet/${details.source}`}>
                              <ShortTextControl
                                text={details.source}
                                numberOfChars={20}
                                textAlignClass="justify-content-end"
                              />
                            </Link>
                          </div>
                        </>
                      }
                      useLineSeparator
                    />
                    <LabelledValueControl
                      label="From account:"
                      value={
                        details.source === '0x0000000000000000000000000000000000000000'
                          ? 'issuance'
                          : null ?? details.source_user?.spellsofgenesis.username ?? ''
                      }
                      useLineSeparator
                    />
                    <LabelledValueControl
                      label="To:"
                      value={
                        <>
                          <div className="sm-down">
                            <Link to={`/wallet/${details.destination}`}>
                              <ShortTextControl text={details.destination} numberOfChars={12} />
                            </Link>
                          </div>
                          <div className="md-up">
                            <Link to={`/wallet/${details.destination}`}>
                              <ShortTextControl
                                text={details.destination}
                                numberOfChars={20}
                                textAlignClass="justify-content-end"
                              />
                            </Link>
                          </div>
                        </>
                      }
                      useLineSeparator
                    />
                    <LabelledValueControl
                      label="To account:"
                      value={details.destination_user?.spellsofgenesis.username ?? ''}
                      useLineSeparator
                    />
                    <LabelledValueControl
                      label="Quantity:"
                      value={
                        <span className="badge bg-secondary">
                          x{details.source === selectedAddress ? '-' : ''}
                          {details?.adaptedQuantity != null ? details?.adaptedQuantity.toString() : details.quantity}
                        </span>
                      }
                    />
                  </div>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </div>
  );
}
