import React from 'react';
import { ClipLoader } from 'react-spinners';

export default function LoaderControl(): JSX.Element {
  return (
    <div className="text-center">
      <ClipLoader />
    </div>
  );
}
