import React from 'react';
import { Col, Row } from 'react-bootstrap';
import useMediaQuery from '../../hooks/use-media-query';
import MediaQuery from '../../models/media-query';

export default function UserAddressPlaceholder(): JSX.Element {
  const maxXs = useMediaQuery(MediaQuery.maxXs);

  return (
    <Row className="justify-content-center">
      <Col xs="6" sm="6" md="8" lg="6" xl="6" className="placeholder-glow">
        <div className="placeholder rounded w-100" style={{ height: maxXs ? '48px' : '94px' }} />
      </Col>
    </Row>
  );
}
