import React, { useState } from 'react';
import './collection-view-component.scss';
import 'lightgallery/scss/lightgallery.scss';
import LightGallery from 'lightgallery/react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import hash from 'object-hash';
import { BalanceCollection } from '../../models/balance-collection-model';
import UserAddresses from '../../models/user-addresses-model';
import WithdrawComponent from '../withdraw/withdraw-component';
import { WalletOrb } from '../../models/wallet-orb-model';
import WalletOrbsCardComponent from '../wallet-orbs-card/wallet-orbs-card-component';
import { replaceIPFSGateway } from '../../helpers/tools';

export default function CollectionViewComponent(params: {
  item: BalanceCollection;
  selectedAddress: string;
  userAddresses: UserAddresses;
}): JSX.Element {
  const { item, selectedAddress, userAddresses } = params;

  const [collectionItem] = useState<BalanceCollection>(item);
  const [isExpanded, setIsExpanded] = useState(false);
  const [withdrawOrb, setWithdrawOrb] = useState<WalletOrb | undefined>(undefined);
  const [withdrawDate, setWithdrawDate] = useState('');

  return (
    <div className="mb-2 pl-2 mn-blocks">
      <div className="row mw-200 mb-4">
        <Col>
          <div className="card w-100 py-2 px-4">
            <Row className="media">
              <Col xs={12} md={4} className="d-flex align-items-center">
                <div className="card-img-actions">
                  <LightGallery
                    licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
                    thumbHeight="5rem"
                    startAnimationDuration={0}
                    speed={0}
                    toggleThumb={false}
                    backdropDuration={200}
                    slideEndAnimation
                    controls={false}
                    counter={false}
                    download={false}
                  >
                    <a
                      href={replaceIPFSGateway(collectionItem.imageUrl)}
                      data-src={replaceIPFSGateway(collectionItem.imageUrl)}
                    >
                      <img
                        src={replaceIPFSGateway(collectionItem.imageUrl)}
                        className="card-img"
                        loading="lazy"
                        alt={collectionItem.name}
                      />
                    </a>
                  </LightGallery>
                </div>
              </Col>
              <Col xs={12} md={8}>
                <div className="media-body">
                  <div className="mn-block-title">
                    <h4 className="media-title collectionName">{collectionItem.name}</h4>
                    <span>
                      <Link to={`/collection/${collectionItem.id}`}>View collection</Link>
                    </span>
                  </div>
                  <p className="collectionDescription">{collectionItem.description}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </div>
      <Row xs={1} sm={2} md={3} lg={4} className="g-4">
        {collectionItem.orbs?.slice(0, isExpanded ? Number.MAX_SAFE_INTEGER : 8).map((orb) => (
          <Col key={hash(orb)}>
            <WalletOrbsCardComponent
              userAddresses={userAddresses}
              setWithdrawDate={setWithdrawDate}
              setWithdrawOrb={setWithdrawOrb}
              orb={orb}
              collectionItem={collectionItem}
              selectedAddress={selectedAddress}
            />
          </Col>
        ))}
      </Row>
      {collectionItem.orbs?.length > 8 ? (
        <div className="mt-5">
          <button type="button" className="m-auto d-block blue-link fs-5" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Hide' : 'View all'}
          </button>
        </div>
      ) : (
        ''
      )}
      {withdrawOrb && (
        <WithdrawComponent
          key={withdrawDate}
          orb={withdrawOrb}
          userAddresses={userAddresses}
          selectedAddress={selectedAddress}
        />
      )}
    </div>
  );
}
