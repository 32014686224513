import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import axios from 'axios';
import { CollectionDetails } from '../../models/collection-details-model';
import { Transactions } from '../../models/transactions-model';
import './collection-details-component.scss';
import { Asset } from '../../models/asset-model';
import SortComponent, { SortingValue } from '../sort/sort-component';
import Sorting from '../../enums/sorting-enum';
import HelpInfoComponent from '../help-info/help-info-component';
import OrbsCardComponent from '../orbs-card/orbs-card-component';
import TransactionListComponent from '../transaction-list/transaction-list-component';
import MultiSelectComponent from '../multi-select/multi-select-component';
import Blockchain from '../../enums/blockchain-enum';
import { capitalizeFirstLetter, scrollToTop } from '../../helpers/tools';
import NoCollectiblesComponent from '../no-collectibles/no-collectibles-components';
import NoCollectiblesType from '../../enums/no-collectibles-type-enum';
import LoaderControl from '../../controls/loader/loader-control';
import { getCollectionDetailsAsync, getTransactionsAsync } from '../../services/api-service';
import { SelectOption } from '../../models/select-option-model';

export default function CollectionDetailsComponent(): JSX.Element {
  type Params = {
    collectionName: string;
  };

  const [collectionDetails, setCollectionDetails] = useState<CollectionDetails>();
  const [assetIndices, setAssetIndices] = useState<string[]>([]);
  const [collectionDetailsLoading, setCollectionDetailsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transactions>();
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const { collectionName } = useParams<keyof Params>() as Params;
  const [hasMoreData, setHasMoreData] = useState(true);
  const [lastIndex, setLastIndex] = useState(0);
  const [blockchainFilter, setBlockchainFilter] = useState<string[]>([]);

  const assets = useMemo<{ [key: string]: Asset }>(() => {
    const assetMap: { [key: string]: Asset } = {};
    if (collectionDetails) {
      const keys = Object.keys(collectionDetails.data.assets);
      keys.forEach((key) => {
        const asset = collectionDetails.data.assets[key];
        const backupAssetId = asset?.imageUrl && asset.imageUrl.split('/').reverse()[0];
        assetMap[asset.assetId ?? backupAssetId] = asset;
      });
    }
    return assetMap;
  }, [collectionDetails]);

  const availableBlockchains = useMemo(() => {
    return [
      { value: Blockchain.Counterparty, label: capitalizeFirstLetter(Blockchain.Counterparty) },
      { value: Blockchain.Ethereum, label: capitalizeFirstLetter(Blockchain.Ethereum) },
      { value: Blockchain.Klaytn, label: capitalizeFirstLetter(Blockchain.Klaytn) },
    ];
  }, []);

  const filteredAssets = useMemo(() => {
    const keys = Object.keys(assets);
    const hasBlockchain = keys.map(
      (key) =>
        assets[key].contracts &&
        assets[key].contracts
          .map((c) => c.blockchain)
          .some((v) => blockchainFilter.includes(v) || !blockchainFilter || blockchainFilter.length === 0)
    );

    const filtered: { [key: string]: Asset } = {};
    hasBlockchain.forEach((x, i) => {
      if (x) {
        filtered[keys[i]] = assets[keys[i]];
      }
    });

    return filtered;
  }, [blockchainFilter, assets]);

  const fetchMoreData = (): void => {
    const allKeys = Object.keys(filteredAssets);
    if (assetIndices && assetIndices.length >= allKeys.length) {
      setHasMoreData(false);
    }

    let newIndices: string[] = [];
    const maxIndex = Math.min(lastIndex + 20, allKeys.length);
    for (let i = lastIndex; i < maxIndex; i += 1) {
      const key: string = allKeys[i];
      const asset = filteredAssets[key];
      if (asset) {
        asset.index = i;
        const backupAssetId = asset.imageUrl && asset.imageUrl.split('/').reverse()[0];
        newIndices.push(asset.assetId ?? backupAssetId);
      }
    }

    newIndices = (assetIndices || []).concat(newIndices);

    setAssetIndices(newIndices);
    setLastIndex(newIndices.length);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getCollectionDetailsAsync(collectionName, cancelToken).then((col) => {
      setCollectionDetails(col);
      setCollectionDetailsLoading(false);
      scrollToTop();
    });

    getTransactionsAsync(cancelToken, collectionName).then((trx) => {
      setTransactions(trx);
      setTransactionsLoading(false);
    });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    fetchMoreData();
  }, [collectionDetailsLoading, blockchainFilter]);

  const onCollectionNameSort = (sortingValue: SortingValue) => {
    if (assetIndices && sortingValue.sorting !== Sorting.None) {
      const indicator = sortingValue.sorting === Sorting.Ascending ? 1 : -1;
      setAssetIndices([...assetIndices.sort((item1, item2) => (item1 > item2 ? indicator : -1 * indicator))]);
    }
  };

  const onBlockchainFilter = (values: MultiValue<SelectOption>) => {
    setBlockchainFilter(values.map((x) => x.value));
  };

  return (
    <div id="page-collection">
      <h1>Collection</h1>
      <div>
        <div id="collection-description">
          <img id="collection-image" alt="" src={collectionDetails?.data?.collection?.imageUrl} />
          <h3>{collectionDetails?.data?.collection?.name}</h3>
          <p>{collectionDetails?.data?.collection?.description}</p>
        </div>
        <div className="tab-content w-100">
          <Tabs defaultActiveKey="collections" id="collections-tabs" className="mb-3">
            <Tab
              eventKey="collections"
              title={
                <span className="cursor-pointer">
                  Orbs
                  <HelpInfoComponent
                    infoTitle="Orbs"
                    infoText='ORB means "Ownership Read on the Blockchain".\n\nOrb is a combination of a token - containing blockchain information - and asset - containing off-chain information, also called metadata.'
                  />
                </span>
              }
              key="collections"
            >
              {collectionDetailsLoading ? (
                <LoaderControl />
              ) : (
                <>
                  <div className="row my-4">
                    <Col xs={12} md={3} lg={3}>
                      <SortComponent onSort={onCollectionNameSort} caption="Name" />
                    </Col>
                    {/* TODO: remove style when filters will be needed */}
                    <Col xs={12} md={8} lg={5} style={{ display: 'none' }}>
                      <MultiSelectComponent
                        placeholder="Select Blockchain(s)…"
                        options={availableBlockchains}
                        onChange={onBlockchainFilter}
                        values={blockchainFilter}
                      />
                    </Col>
                  </div>
                  {assetIndices && (
                    <InfiniteScroll
                      className="tab-pane fade active show"
                      dataLength={assetIndices.length}
                      next={fetchMoreData}
                      hasMore={hasMoreData || true}
                      loader={<div />}
                    >
                      <Row xs={1} sm={2} md={3} lg={4} className="g-4">
                        {assetIndices.map(
                          (item) =>
                            filteredAssets[item] && (
                              <Col key={filteredAssets[item]?.assetId}>
                                <OrbsCardComponent
                                  item={filteredAssets[item]}
                                  defaultImagePath={collectionDetails?.data?.collection?.imageUrl || ''}
                                />
                              </Col>
                            )
                        )}
                      </Row>
                      {!assetIndices.find((x) => filteredAssets[x] !== undefined) && (
                        <NoCollectiblesComponent noCollectiblesType={NoCollectiblesType.Filters} />
                      )}
                    </InfiniteScroll>
                  )}
                </>
              )}
            </Tab>
            <Tab eventKey="transactions" title="Transactions" key="transactions">
              {transactionsLoading ? (
                <LoaderControl />
              ) : (
                transactions?.data?.length && (
                  <div>
                    <TransactionListComponent transactions={transactions} />
                  </div>
                )
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
