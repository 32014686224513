import React from 'react';
import Select, { SingleValue, StylesConfig } from 'react-select';
import { SelectColors } from '../../enums/select-colors-enum';
import { SelectOption } from '../../models/select-option-model';
import './select-component.scss';

interface SelectComponentProps {
  options: SelectOption[];
  onChange: (value: SingleValue<SelectOption>) => void;
  value: SelectOption;
  color?: SelectColors;
}

type IsMulti = false;

export default function SelectComponent({ options, onChange, value, color }: SelectComponentProps): JSX.Element {
  const customStyles: StylesConfig<SelectOption, IsMulti> = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
      transition: '.2s ease-in-out',
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <Select<SelectOption>
      styles={customStyles}
      options={options}
      value={value}
      onChange={onChange}
      isSearchable={false}
      className={`react-select-${color}`}
      classNamePrefix="react-select"
      components={{
        IndicatorSeparator: () => null,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#32a0fc',
          primary50: 'rgba(0,0,0,0.20)',
          primary25: 'rgba(0,0,0,0.10)',
        },
      })}
    />
  );
}

SelectComponent.defaultProps = {
  color: SelectColors.White,
};
