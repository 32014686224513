import React, { RefObject, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import './main-menu-component.scss';
import { Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { toast } from 'react-toastify';
import orbLogo from '../../assets/images/logo-orb-explorer.png';
import WalletMenu from '../../controls/wallet-menu/wallet-menu-component';
import SearchInputControl from '../../controls/search-input/search-input-control';
import { scrollToTop } from '../../helpers/tools';
import {
  validateEthAddress,
  validateEthTxId,
  validateKlaytnAddress,
  validateXcpAddress,
  validateXcpTxId,
} from '../../services/address-validator';

export default function MainMenuComponent(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const [addressOrTxId, setAddressOrTxId] = useState('');

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOGLE_ANALYTICS_KEY || '');
    ReactGA.pageview(location.pathname + location.search);
    scrollToTop();
  }, [location]);

  const goToAddressOrTxId = (addressOrTx: string) => {
    if (addressOrTxId) {
      const isAddress =
        validateEthAddress(addressOrTx) || validateXcpAddress(addressOrTx) || validateKlaytnAddress(addressOrTx);
      const isTxId = validateEthTxId(addressOrTx) || validateXcpTxId(addressOrTx);

      if (isAddress) {
        navigate(`/wallet/${addressOrTx}`);
      } else if (isTxId) {
        navigate(`/transactions/${addressOrTx}`);
      } else {
        toast('Incorrect search value');
      }
    }
  };

  const handleSearchKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      goToAddressOrTxId(addressOrTxId);
    }
  };

  const handleCancelClick = (control: RefObject<HTMLInputElement>): void => {
    if (control?.current) {
      const ctr = control.current;
      ctr.value = '';
    }
    navigate(`/transactions`);
  };

  return (
    <header>
      <Navbar expand="md">
        <Navbar.Brand>
          <Link to="/">
            <img src={orbLogo} loading="lazy" alt="Orb Explorer" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarTog">
          <FontAwesomeIcon icon={faBars as IconProp} className="navToggle" />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarTog">
          <Form className="d-flex">
            <SearchInputControl
              placeholder="Search address or transaction id"
              onKeyPress={(event) => handleSearchKeyPress(event)}
              onChange={(event) => setAddressOrTxId(event.target.value)}
              onCancel={(event) => handleCancelClick(event)}
            />
          </Form>
          <Nav className="ms-auto">
            <NavDropdown title="Browse" className="pe-3">
              <Link to="/collections" className="navbar-nav-link dropdown-item">
                Collections
              </Link>
              <Link to="/transactions" className="navbar-nav-link dropdown-item">
                Transactions
              </Link>
            </NavDropdown>
            <WalletMenu />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}
