import Web3 from 'web3';
import { extractAccount } from '../../helpers/tools';
import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

export default class Web3Service extends BaseWalletService implements WalletService {
  init = async (
    newChainHandler?: (chainId: string | number) => void,
    newNetworkHandler?: (networkId: string | number) => void,
    newAccountHandler?: (newAccounts: string | string[]) => void,
    disconnectHandler?: () => void
  ) => {
    super.init(newChainHandler, newNetworkHandler, newAccountHandler, disconnectHandler);

    if (this.isWalletInstalled()) {
      this.handleNewChain(1);
      this.handleNewNetwork(1);
    }
  };

  connect = async (): Promise<void> => {
    let account = extractAccount(await this.getAccounts());
    if (this.newAccountHandler) {
      if (!account) {
        account = extractAccount(await web3.eth.requestAccounts());
      }

      this.handleNewAccounts(extractAccount(account));
    }

    this.handleNewChain(1);
    this.handleNewNetwork(1);
  };

  isWalletInstalled = (): boolean => {
    return !!web3?.eth;
  };

  getAccounts = async (): Promise<string> => {
    const accounts = await web3.eth.getAccounts();

    this.handleNewChain(1);
    this.handleNewNetwork(1);

    return extractAccount(accounts);
  };

  getName = (): string => {
    return 'Web3';
  };

  getDownloadWalletPath = (): string => {
    return 'https://metamask.io';
  };

  personalSign = async (message: string, password?: string): Promise<string> => {
    const account = await this.getAccounts();
    const result = web3.eth.personal.sign(message, account, password || '');
    return result;
  };
}
