import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import noImg from '../../assets/images/no-image-found.jpg';
import NoCollectiblesType from '../../enums/no-collectibles-type-enum';

export default function NoCollectiblesComponent(params: { noCollectiblesType: NoCollectiblesType }): JSX.Element {
  const { noCollectiblesType } = params;

  return (
    <div className="row mn-blocks mw-200 mb-4">
      <Col>
        <div className="card w-100 py-2 px-4">
          <Row className="media">
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-center justify-content-md-start">
              <img src={noImg} height="90px" loading="lazy" alt="Missing pic" />
            </Col>
            <Col xs={12} md={8}>
              <div className="media-body">
                <div className="mn-block-title">
                  <h4 className="media-title collectionName">
                    No{' '}
                    {noCollectiblesType === NoCollectiblesType.Transactions
                      ? noCollectiblesType
                      : NoCollectiblesType.Collectibles}
                  </h4>
                  <span>
                    <Link to="/collections">View collections</Link>
                  </span>
                </div>
                <p className="collectionDescription">
                  No{' '}
                  {noCollectiblesType === NoCollectiblesType.Transactions
                    ? noCollectiblesType
                    : NoCollectiblesType.Collectibles}{' '}
                  found at this address.
                  {noCollectiblesType === NoCollectiblesType.Filters && 'Try to change filters.'}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
}
