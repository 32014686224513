import React from 'react';
import hash from 'object-hash';
import './help-info-component.scss';
import { CPopover } from '@coreui/react';
import useIsOnMobile from '../../hooks/use-is-on-mobile';

export default function HelpInfoComponent(props: { infoTitle: string; infoText: string }): JSX.Element {
  const { infoText, infoTitle } = props;
  const isOnMobile = useIsOnMobile();

  return (
    <CPopover
      title={infoTitle}
      content={
        <span>
          {infoText.split('\\n').map((x) => {
            return (
              <span key={hash(x)}>
                {x}
                <br />
              </span>
            );
          })}
        </span>
      }
      placement="auto"
      trigger={isOnMobile ? 'click' : 'hover'}
    >
      <sup>
        <big>
          &nbsp;<b>?</b>
        </big>
      </sup>
    </CPopover>
  );
}
