import React from 'react';
import { Card } from 'react-bootstrap';
import './orbs-card-component.scss';
import LazyCardImageComponent from '../lazy-card-image/lazy-card-image-component';
import { Asset } from '../../models/asset-model';
import ShortTextControl from '../../controls/short-text/short-text-control';

type OrbsCardComponentProps = {
  item: Asset;
  defaultImagePath: string;
};

export default function OrbsCardComponent({ item, defaultImagePath }: OrbsCardComponentProps): JSX.Element {
  return (
    <Card className="cards orbs-card-component">
      <LazyCardImageComponent imageUrl={item.imageUrl} name={item.assetId} defaultImageUrl={defaultImagePath} />
      <Card.Body>
        <h5>
          <ShortTextControl
            text={item?.contracts[0].address}
            numberOfChars={11}
            textAlignClass="justify-content-between"
          />
        </h5>
        {/* TODO: remove style when filters will be needed */}
        <h6 style={{ display: 'none' }}>{item.contracts[0].blockchain}</h6>
      </Card.Body>
    </Card>
  );
}
