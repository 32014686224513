import React from 'react';
import { Col, Row } from 'react-bootstrap';
import imgCT from '../../assets/images/in-the-spotlight/in-the-spotlight-casa-tookan.png';
import imgSOG from '../../assets/images/in-the-spotlight/in-the-spotlight-spell-of-genesis.png';
import imgNFTStore from '../../assets/images/in-the-spotlight/in-the-spotlight-ntf-store.jpg';
import './spotlight-component.scss';
import ClickableCardComponent from '../clickable-card/clickable-card-component';

export default function SpotlightComponent(): JSX.Element {
  const spotlights = [
    {
      title: 'Casa Tookan',
      text: 'Next-generation multi-chain wallet & DApp browser with integrated newsreader',
      image: imgCT,
      link: 'https://crystalsuite.com/casa-tookan-wallet/',
    },
    {
      title: 'NFT Store',
      text: 'Crypto assets since 2015 as collectible cards to be played in our game Spells of Genesis!',
      image: imgNFTStore,
      link: 'https://crystalsuite.com/store/',
    },
    {
      title: 'Spells of Genesis',
      text: '1st blockchain-based mobile game ever made with Trading Card Game (TCG) functionalities.',
      image: imgSOG,
      link: 'https://spellsofgenesis.com',
    },
  ];

  return (
    <div className="mn-blocks">
      <h4>Spotlight</h4>
      <Row xs={1} sm={2} md={3} className="g-4">
        {spotlights.map(({ title, text, image, link }) => (
          <Col key={`spotlight-${title}`}>
            <ClickableCardComponent
              link={link}
              image={image}
              title={title}
              text={text}
              isInternalRouting={false}
              buttonText="Learn More"
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
