import TransactionType from '../enums/transaction-type';

export default class TransactionItem {
  blockchain: string;
  collection: string;
  contract: string;
  destination: string;
  destinationUsername: string;
  explorer: string;
  image: string;
  quantity: number;
  source: string;
  sourceUsername: string;
  time: string;
  txId: string;
  transactionType: TransactionType;
}
