import Web3 from 'web3';
import { validate } from 'bitcoin-address-validation';
import keccak256 from 'keccak256';

function toChecksum(address: string): string {
  const addressHash = keccak256(address.slice(2));
  let checksumAddress = '0x';
  for (let i = 0; i < 40; i += 1)
    checksumAddress +=
      parseInt(addressHash[i + 2], 16) > 7 && address[i + 2] ? address[i + 2].toUpperCase() : address[i + 2];
  return checksumAddress;
}

function validateEthAddress(address: string): boolean {
  return Web3.utils.isAddress(address);
}

function validateXcpAddress(address: string): boolean {
  return validate(address);
}

function validateKlaytnAddress(address: string): boolean {
  const re = /0x[0-9A-Fa-f]{40}/g;
  const checksum = toChecksum(address);
  return checksum.toLowerCase() === address.toLowerCase() && re.test(address);
}

function validateEthTxId(txId: string): boolean {
  const re = /0x[0-9A-Fa-f]{64}/g;
  return txId != null && txId.length === 66 && re.test(txId);
}

function validateXcpTxId(txId: string): boolean {
  const re = /[0-9A-Fa-f]{64}/g;
  return txId != null && txId.length === 64 && re.test(txId);
}

export { validateEthAddress, validateXcpAddress, validateKlaytnAddress, validateEthTxId, validateXcpTxId };
