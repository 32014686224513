import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import LightGallery from 'lightgallery/react';
import { WalletOrb } from '../../models/wallet-orb-model';
import { BalanceCollection } from '../../models/balance-collection-model';
import { replaceIPFSGateway } from '../../helpers/tools';
import WalletHelper from '../../helpers/wallet-helper';
import UserAddresses from '../../models/user-addresses-model';
import '../orbs-card/orbs-card-component.scss';

type WalletOrbsCardComponentProps = {
  orb: WalletOrb;
  collectionItem: BalanceCollection;
  userAddresses: UserAddresses;
  selectedAddress: string;
  setWithdrawDate: Dispatch<SetStateAction<string>>;
  setWithdrawOrb: Dispatch<SetStateAction<WalletOrb | undefined>>;
};

export default function WalletOrbsCardComponent({
  orb,
  collectionItem,
  userAddresses,
  setWithdrawDate,
  setWithdrawOrb,
  selectedAddress,
}: WalletOrbsCardComponentProps): JSX.Element {
  const [ownWallet, setOwnWallet] = useState(false);

  useEffect(() => {
    WalletHelper.getEthereumWallet().then((res) => {
      setOwnWallet(!!(userAddresses.ethereum && userAddresses.ethereum === res));
    });
  }, []);

  const isInsideCasaTookan = !!window.webXCP;

  return (
    <Card className="cards orbs-card-component">
      <LightGallery
        licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
        elementClassNames="cursor-pointer flex-grow-1"
        startAnimationDuration={0}
        speed={0}
        toggleThumb={false}
        backdropDuration={200}
        slideEndAnimation
        controls={false}
        counter={false}
        download={false}
        plugins={[lgThumbnail]}
      >
        <img
          src={replaceIPFSGateway(orb.asset.image) ?? collectionItem.imageUrl}
          className="card-img"
          loading="lazy"
          alt={orb.asset.name}
        />
      </LightGallery>
      <Card.Body>
        {orb.asset && (
          <div>
            <h5>{orb.asset.name || orb.contract || 'Missing name'}</h5>
            <hr />
          </div>
        )}
        <div className="d-flex justify-content-between">
          <span>Blockchain:</span>
          <span className="d-flex align-items-center">
            {orb.chain} <span className={`me-0 ms-1 blockchain-img-${orb.chain}`} />
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span>Quantity:</span>
          <span>{orb.adaptedQuantity ? orb.adaptedQuantity : orb.quantity}</span>
        </div>
        {collectionItem.id === 'eSog' &&
          (ownWallet || isInsideCasaTookan) &&
          selectedAddress === userAddresses.firstOasisKlaytn && (
            <div className="d-flex mt-3 flex-nowrap">
              <Button
                className="btn btn-primary"
                onClick={() => {
                  setWithdrawOrb(orb);
                  setWithdrawDate(new Date().toString());
                }}
              >
                Withdraw <i className="icon-play3 ml-2" />
              </Button>
            </div>
          )}
      </Card.Body>
    </Card>
  );
}
