import React from 'react';
import HomeComponent from '../home/home-component';
import SpotlightComponent from '../spotlight/spotlight-component';
import SelectedCollectionsComponent from '../selected-collections/selected-collections-component';

const App = function app(): JSX.Element {
  return (
    <div className="App">
      <main className="main">
        <div className="page-content">
          <HomeComponent />
          <div className="content content-boxed use-layout">
            <SpotlightComponent />
            <SelectedCollectionsComponent />
          </div>
        </div>
      </main>
    </div>
  );
};

export default App;
