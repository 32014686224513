import React from 'react';
import { Form } from 'react-bootstrap';
import Select, { SingleValue, StylesConfig } from 'react-select';
import { SelectColors } from '../../enums/select-colors-enum';
import Sorting from '../../enums/sorting-enum';

export interface SortingValue {
  sorting: Sorting;
  sortingField?: string;
}

export interface SortingName {
  caption: string;
  sortingField: string;
}

export interface SortingOption {
  label: string;
  value: SortingValue;
}

interface SortComponentProps {
  onSort: (value: SortingValue) => void;
  selectedValue?: SortingOption;
  fields?: SortingName[];
  caption?: string;
  color?: SelectColors;
}

export default function SortComponent({
  fields,
  onSort,
  caption,
  selectedValue,
  color,
}: SortComponentProps): JSX.Element {
  const aToZ = 'A-Z';
  const zToA = 'Z-A';

  const onChange = (value: SingleValue<SortingOption>): void => {
    if (value) {
      onSort(value.value);
    }
  };

  const options: SortingOption[] = [
    {
      label: Sorting.None,
      value: { sorting: Sorting.None },
    },
    ...(fields && fields.length
      ? fields
          .map((f) => [
            {
              label: `${f.caption} ${aToZ}`,
              value: { sorting: Sorting.Ascending, sortingField: f.sortingField },
            },
            {
              label: `${f.caption} ${zToA}`,
              value: { sorting: Sorting.Descending, sortingField: f.sortingField },
            },
          ])
          .flat()
      : []),
    ...(caption
      ? [
          {
            label: `${caption} ${aToZ}`,
            value: { sorting: Sorting.Ascending },
          },
          {
            label: `${caption} ${zToA}`,
            value: { sorting: Sorting.Descending },
          },
        ]
      : []),
  ];

  type IsMulti = false;
  const customStyles: StylesConfig<SortingOption, IsMulti> = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
      transition: '.2s ease-in-out',
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <Form.Label className="m-0 nowrap me-3 ps-1">Sort by</Form.Label>
      <div className="flex-grow-1">
        <Select<SortingOption>
          styles={customStyles}
          options={options}
          value={selectedValue}
          onChange={onChange}
          isSearchable
          className={`react-select-${color}`}
          classNamePrefix="react-select"
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#32a0fc',
              primary50: 'rgba(0,0,0,0.20)',
              primary25: 'rgba(0,0,0,0.10)',
            },
          })}
        />
      </div>
    </div>
  );
}

SortComponent.defaultProps = {
  caption: '',
  color: SelectColors.White,
  selectedValue: undefined,
  fields: undefined,
};
