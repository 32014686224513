import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import hash from 'object-hash';
import { MultiValue } from 'react-select';
import axios, { CancelTokenSource } from 'axios';
import { BalanceCollection } from '../../models/balance-collection-model';
import { Transactions } from '../../models/transactions-model';
import BlockchainAddressComponent from '../blockchain-address/blockchain-address-component';
import CollectionViewComponent from '../collection-view/collection-view-component';
import NoCollectiblesComponent from '../no-collectibles/no-collectibles-components';
import TransactionListComponent from '../transaction-list/transaction-list-component';
import { User } from '../../models/user-model';
import UserAddresses from '../../models/user-addresses-model';
import Blockchain from '../../enums/blockchain-enum';
import HelpInfoComponent from '../help-info/help-info-component';
import MultiSelectComponent from '../multi-select/multi-select-component';
import { scrollToTop } from '../../helpers/tools';
import NoCollectiblesType from '../../enums/no-collectibles-type-enum';
import LoaderControl from '../../controls/loader/loader-control';
import { getTransactionsAsync, getUserAddressesAsync, getWalletBalanceAsync } from '../../services/api-service';
import BalancePlaceholder from '../placeholders/balance-placeholder';
import UserAddressPlaceholder from '../placeholders/user-address-placeholder';
import { SelectOption } from '../../models/select-option-model';

export default function WalletComponent(): JSX.Element {
  type Params = {
    selectedAddress: string;
  };

  const [collection] = useQueryState('collection', '');
  const [collectionFilter, setCollectionFilter] = useState<string[]>(collection.length ? [collection] : []);

  const [balance, setBalance] = useState<BalanceCollection[]>();
  const [balanceLoading, setBalanceLoading] = useState(true);

  const [filteredBalance, setFilteredBalance] = useState<BalanceCollection[]>();
  const { selectedAddress } = useParams<keyof Params>() as Params;

  const [transactions, setTransactions] = useState<Transactions>();
  const [transactionsLoading, setTransactionsLoading] = useState(true);

  const [user, setUser] = useState<User>({} as User);
  const [userAddressesLoading, setUserAddressesLoading] = useState(true);
  const [userAddresses, setUserAddresses] = useState<UserAddresses>({
    counterparty: '',
    ethereum: '',
    firstOasisKlaytn: '',
  });

  const availableCollections = useMemo(() => {
    const sortedItems = (balance || []).map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
    sortedItems.sort((s1, s2) => (s1.value > s2.value ? 1 : -1));
    return sortedItems;
  }, [balance]);

  function filterByCollection(sourceCollection: BalanceCollection[], filter: string[]): BalanceCollection[] {
    return sourceCollection.filter((x) => filter.length === 0 || filter.includes(x.id));
  }

  const onCollectionFilter = (values: MultiValue<SelectOption>) => {
    setCollectionFilter(values.map((x) => x.value));
  };

  function fetchData(cancelToken: CancelTokenSource): void {
    setBalanceLoading(true);
    getWalletBalanceAsync(selectedAddress, cancelToken).then((res) => {
      setBalance(res?.data?.collections);
      setFilteredBalance(res?.data?.collections);
      setBalanceLoading(false);
      scrollToTop();
    });

    setTransactionsLoading(true);
    getTransactionsAsync(cancelToken, '', selectedAddress).then((res) => {
      setTransactions(res);
      setTransactionsLoading(false);
    });
  }

  function fetchUserAddresses(cancelToken: CancelTokenSource): void {
    if (selectedAddress && !user?.data?.addresses?.find((c) => c.address === selectedAddress)) {
      setUserAddressesLoading(true);
      getUserAddressesAsync(selectedAddress, cancelToken).then((userResponse: User) => {
        setUser(userResponse);

        const userResponseAddresses = { counterparty: '', ethereum: '', firstOasisKlaytn: '' };
        userResponse.data.addresses?.forEach((address) => {
          if (address.blockchain === Blockchain.Counterparty) {
            userResponseAddresses.counterparty = address.address;
          } else if (address.blockchain === Blockchain.Ethereum) {
            userResponseAddresses.ethereum = address.address;
          } else if (address.blockchain === Blockchain.FirstOasisKlaytn) {
            userResponseAddresses.firstOasisKlaytn = address.address;
          }
        });
        setUserAddresses(userResponseAddresses);
        setUserAddressesLoading(false);
      });
    }
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    fetchData(cancelToken);
    fetchUserAddresses(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [selectedAddress, collection]);

  useEffect(() => {
    setFilteredBalance(filterByCollection(balance || [], collectionFilter));
  }, [collectionFilter]);

  return (
    <div className="mt-5">
      <h1>Wallet</h1>
      <div>
        <div id="wallet-block" className="pb-5">
          {userAddressesLoading ? <UserAddressPlaceholder /> : <BlockchainAddressComponent user={user} />}
        </div>
        <div className="tab-content w-100 order-2 order-md-2">
          <Tabs className="nav-tabs-responsive  mb-3">
            <Tab
              eventKey="collections"
              title={
                <span className="cursor-pointer">
                  Orbs
                  <HelpInfoComponent
                    infoTitle="Orbs"
                    infoText='ORB means "Ownership Read on the Blockchain".\n\nOrb is a combination of a token - containing blockchain information - and asset - containing off-chain information, also called metadata.'
                  />
                </span>
              }
            >
              {balanceLoading ? (
                <BalancePlaceholder />
              ) : (
                <div id="wallet-collection">
                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <MultiSelectComponent
                        placeholder="Select Collection(s)…"
                        options={availableCollections}
                        onChange={onCollectionFilter}
                        values={collectionFilter}
                      />
                    </Col>
                  </Row>
                  {!balanceLoading && filteredBalance && filteredBalance.length > 0 ? (
                    filteredBalance.map((x) => (
                      <CollectionViewComponent
                        item={x}
                        key={hash(x)}
                        selectedAddress={selectedAddress}
                        userAddresses={userAddresses}
                      />
                    ))
                  ) : (
                    <NoCollectiblesComponent
                      noCollectiblesType={
                        collectionFilter.length > 0 ? NoCollectiblesType.Filters : NoCollectiblesType.Collectibles
                      }
                    />
                  )}
                </div>
              )}
            </Tab>
            <Tab eventKey="transactions" title={<span className="cursor-pointer">Transactions</span>}>
              <div>
                {transactionsLoading ? (
                  <LoaderControl />
                ) : (
                  transactions && (
                    <div>
                      <TransactionListComponent transactions={transactions} hideBlockchainFilter />
                    </div>
                  )
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
