/* eslint-disable no-console */
export default abstract class BaseWalletService {
  newChainHandler?: unknown;

  newNetworkHandler?: unknown;

  newAccountHandler?: unknown;

  disconnectHandler?: () => void;

  async init(
    newChainHandler?: (chainId: string | number) => void,
    newNetworkHandler?: (networkId: string | number) => void,
    newAccountHandler?: (newAccounts: string | string[]) => void,
    disconnectHandler?: () => void
  ): Promise<void> {
    this.newChainHandler = newChainHandler;
    this.newNetworkHandler = newNetworkHandler;
    this.newAccountHandler = newAccountHandler;
    this.disconnectHandler = disconnectHandler;
  }

  async disconnect(): Promise<void> {
    this.handleDisconnect();
  }

  public getDownloadWalletPath = (): string => {
    return '';
  };

  protected handleNewAccounts = (newAccounts: string | string[]) => {
    this.logInfo(newAccounts);
    if (this.newAccountHandler) {
      const newAccount = this.newAccountHandler as (newAccounts: string | string[]) => void;
      newAccount(newAccounts);
    }
  };

  protected handleNewChain = (chainId: string | number) => {
    this.logInfo(chainId);

    const newChain = this.newChainHandler as (chainId: string | number) => void;
    if (this.newChainHandler) {
      if (chainId.toString().startsWith('0x')) {
        const chain: number = parseInt(chainId.toString(), 16);
        newChain(chain);
      } else if (Number.isFinite(chainId)) {
        newChain(chainId);
      }
    }
  };

  protected handleNewNetwork = (networkId: string | number) => {
    this.logInfo(networkId);
    if (this.newNetworkHandler) {
      const newNetwork = this.newNetworkHandler as (networkId: string | number) => void;
      newNetwork(networkId);
    }
  };

  protected handleDisconnect = () => {
    if (this.disconnectHandler) {
      this.disconnectHandler();
    }
  };

  logError = (err: unknown) => {
    console.error(err);
  };

  logInfo = (message: unknown) => {
    console.warn(message);
  };
}
