import React, { ChangeEventHandler, KeyboardEventHandler, RefObject, useRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import './search-input-control.scss';

export interface SearchInputProps {
  size?: 'sm' | 'lg';
  maxLength?: number;
  placeholder?: string;
  onKeyPress?: KeyboardEventHandler;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onCancel?: (textControl: RefObject<HTMLInputElement>) => void;
}

export default function SearchInputControl({
  size,
  placeholder,
  maxLength,
  onKeyPress = undefined,
  onChange = undefined,
  onCancel = undefined,
}: SearchInputProps): JSX.Element {
  const textControl = useRef<HTMLInputElement>(null);

  return (
    <InputGroup>
      <Form.Control
        id="search-control"
        ref={textControl}
        className="search-control"
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="basic-addon2"
        size={size}
        maxLength={maxLength}
      />
      <Button variant="outline-secondary" onClick={() => onCancel && onCancel(textControl)} id="button-addon2">
        <i className="icon-cross" />
      </Button>
    </InputGroup>
  );
}

SearchInputControl.defaultProps = {
  size: 'lg',
  placeholder: '',
  maxLength: 100,
  onKeyPress: undefined,
  onChange: undefined,
  onCancel: undefined,
};
