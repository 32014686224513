import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { WalletOrb } from '../../models/wallet-orb-model';
import { capitalizeFirstLetter } from '../../helpers/tools';
import './withdraw-modal-2-component.scss';

interface IWithdrawModalParams {
  closeWithdrawModal2: MouseEventHandler<HTMLButtonElement>;
  gasAvailable: number | undefined;
  gasMinimumRequired: number;
  gasRequired: number | undefined;
  openDepositModal1: () => void;
  openWithdrawModal3: () => void;
  orb: WalletOrb | undefined;
  returnToWithdrawModal1: () => void;
  userHasEnoughBitCrystals: boolean | undefined;
  verifyWithdrawError: boolean;
  verifyWithdrawErrorMessage: string | undefined;
  withdrawBlockchain: string | undefined;
  withdrawModal2Visibility: boolean;
  withdrawVerified: boolean;
}

export default function WithdrawModal2(props: IWithdrawModalParams): JSX.Element {
  const [isOpenState, setOpenState] = useState(false);
  const {
    closeWithdrawModal2,
    gasAvailable,
    gasMinimumRequired,
    gasRequired,
    openDepositModal1,
    openWithdrawModal3,
    orb,
    returnToWithdrawModal1,
    userHasEnoughBitCrystals,
    verifyWithdrawError,
    verifyWithdrawErrorMessage,
    withdrawBlockchain,
    withdrawModal2Visibility,
    withdrawVerified,
  } = props;

  useEffect(() => {
    setOpenState(withdrawModal2Visibility);
  }, [withdrawModal2Visibility]);

  return (
    <Modal
      id="withdraw-modal-2"
      show={isOpenState}
      onHide={() => closeWithdrawModal2}
      animation={false}
      centered
      backdropClassName="modal-backdrop"
    >
      <Modal.Header>
        <Modal.Title>Withdraw on {capitalizeFirstLetter(withdrawBlockchain)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="orb-image">
          <img
            src={orb?.asset.image}
            className="w-100 h-100 p-1 pb-3 d-flex justify-content-center"
            alt="Orb to withdraw"
          />
        </div>
        {withdrawVerified ? (
          <div>
            {verifyWithdrawError ? (
              <p>{verifyWithdrawErrorMessage}</p>
            ) : (
              <>
                <p>The withdrawal fee is:</p>
                <h5>{gasRequired} BCYM</h5>
                <p>You own:</p>
                <h5>{gasAvailable} BCYM</h5>
                {userHasEnoughBitCrystals ? (
                  <div id="buttons">
                    <Button onClick={() => openWithdrawModal3()}>Withdraw</Button>
                  </div>
                ) : (
                  <>
                    <p>You don&apos;t have enough BitCrystals.</p>
                    <p>Please deposit minimum {gasMinimumRequired} BCYM first.</p>
                    <div id="buttons">
                      <Button onClick={() => openDepositModal1()}>Deposit</Button>
                    </div>
                  </>
                )}
                <p>
                  The withdrawal fee is the fee needed to perform a blockchain transaction. The fee is paid in BCYM
                  (BitCrystals on First Oasis). If you do not have enough BCYM, you can recharge your First Oasis
                  address by sending BCY or BCYETH (BitCrystals on Counterparty or Ethereum) to a dedicated deposit
                  address.
                </p>
              </>
            )}
          </div>
        ) : (
          <div id="spinner-container">
            <Spinner animation="grow" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => returnToWithdrawModal1()}>
          Back
        </Button>
        <Button variant="secondary" onClick={closeWithdrawModal2}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
