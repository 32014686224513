import React, { useState } from 'react';
import 'lightgallery/scss/lightgallery.scss';
import './lazy-card-image-component.scss';
import CardImagePlaceholder from '../placeholders/card-image-placeholder';
import { replaceIPFSGateway } from '../../helpers/tools';

export type LazyCardImageComponentProps = {
  imageUrl: string;
  name: string;
  defaultImageUrl?: string;
  usePlaceholder?: boolean;
};

export default function LazyCardImageComponent({
  imageUrl,
  name,
  defaultImageUrl,
  usePlaceholder,
}: LazyCardImageComponentProps): JSX.Element {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className="card-image"
      style={{
        opacity: isLoaded ? '1' : '0.7',
        backgroundImage: isLoaded || usePlaceholder ? '' : `url(${defaultImageUrl})`,
      }}
    >
      <img
        className={isLoaded ? 'fadeIn' : ''}
        src={replaceIPFSGateway(imageUrl)}
        alt={name}
        title={name}
        style={{
          objectFit: 'contain',
          width: isLoaded ? '100%' : '0px',
        }}
        onLoad={() => {
          setIsLoaded(true);
        }}
      />
      {!isLoaded && usePlaceholder && <CardImagePlaceholder />}
    </div>
  );
}

LazyCardImageComponent.defaultProps = {
  defaultImageUrl: '',
  usePlaceholder: false,
};
