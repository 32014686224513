import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Transactions } from '../../models/transactions-model';
import { validateEthTxId, validateXcpTxId } from '../../services/address-validator';
import TransactionListComponent from '../transaction-list/transaction-list-component';
import './transactions-component.scss';
import { getTransactionAsync, getTransactionsAsync } from '../../services/api-service';
import TransactionsPlaceholder from '../placeholders/transactions-placeholder';

export default function TransactionsComponent(): JSX.Element {
  const [transactions, setTransactions] = useState({} as Transactions);
  const [transactionsLoading, setTransactionsLoading] = useState(true);

  const { txId } = useParams();
  const navigate = useNavigate();

  const fetchData = (cancelToken: CancelTokenSource) => {
    const isTxId = txId && (validateEthTxId(txId) || validateXcpTxId(txId));
    if (isTxId) {
      setTransactionsLoading(true);
      getTransactionAsync(txId, cancelToken).then((transactionResponse) => {
        setTransactions(transactionResponse);
        setTransactionsLoading(false);
      });
    } else {
      setTransactionsLoading(true);
      getTransactionsAsync(cancelToken).then((transactionsResponse) => {
        setTransactions(transactionsResponse);
        setTransactionsLoading(false);
      });
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchData(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchData(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [txId]);

  return (
    <div id="page-transactions">
      <h1>Transactions</h1>
      <div>
        {transactionsLoading ? (
          <TransactionsPlaceholder />
        ) : (
          transactions?.data?.length && (
            <div id="allCollections-tab">
              <TransactionListComponent transactions={transactions} />
            </div>
          )
        )}
        {transactions?.data === undefined && transactions.meta && !transactionsLoading && (
          <>
            <p className="pt-3">No transactions have been found.</p>
            <Button onClick={() => navigate(`/transactions`)}>Show all transactions</Button>
          </>
        )}
      </div>
    </div>
  );
}
