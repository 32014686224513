import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

export type LabelledValueControlProps = {
  label: string;
  value: ReactNode;
  useLineSeparator?: boolean;
};

export default function LabelledValueControl({
  label,
  value,
  useLineSeparator,
}: LabelledValueControlProps): JSX.Element {
  return (
    <>
      <Row>
        <Col xl={3} lg={3} md={12} className="fw-bold">
          {label}
        </Col>
        <Col xl={9} lg={9} md={12}>
          {value || '-'}
        </Col>
      </Row>
      {useLineSeparator && <hr className="my-2" />}
    </>
  );
}

LabelledValueControl.defaultProps = {
  useLineSeparator: false,
};
