import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import './deposit-modal-2-component.scss';
import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Blockchain from '../../enums/blockchain-enum';
import { capitalizeFirstLetter } from '../../helpers/tools';

interface IDepositModal2ComponentProps {
  closeDepositModal2: MouseEventHandler<HTMLButtonElement>;
  depositAddress: string | undefined;
  depositBlockchain: Blockchain.Counterparty | Blockchain.Ethereum | undefined;
  depositModal2Visibility: boolean;
  gasMinimumRequired: number | undefined;
}

export default function DepositModal2(props: IDepositModal2ComponentProps): JSX.Element {
  const { closeDepositModal2, depositAddress, depositBlockchain, depositModal2Visibility, gasMinimumRequired } = props;

  const [isOpenState, setOpenState] = useState(false);
  const [buttonIsVisible, setButtonIsVisible] = useState<boolean>(true);

  useEffect(() => {
    setOpenState(depositModal2Visibility);
  }, [depositModal2Visibility]);

  return (
    <Modal id="deposit-modal-2" show={isOpenState} onHide={() => closeDepositModal2} animation={false} centered>
      <Modal.Header>
        <Modal.Title>Deposit on {capitalizeFirstLetter(depositBlockchain)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {buttonIsVisible ? (
          <>
            <p>Please send BitCrystals to the following address.</p>
            <p>Amount to be paid:</p>
            <h6>{gasMinimumRequired} BitCrystals</h6>
          </>
        ) : (
          <>
            <p>
              Your payment is being processed. Depending on the blockchain, the transaction confirmation may take some
              time.
            </p>
            <p>
              Please refresh the page to see the updated BCYM balance. Once you have enough BCYM, you can proceed with
              the withdrawal.
            </p>
          </>
        )}
        {depositAddress ? (
          <>
            {buttonIsVisible && (
              <>
                <CopyToClipboard
                  text={depositAddress}
                  onCopy={() => {
                    toast(`${depositAddress} was copied to your clipboard!`);
                  }}
                >
                  <QRCode id="deposit-qr-code" level="Q" size={128} value={depositAddress} />
                </CopyToClipboard>
                <p className="text-break">{depositAddress}</p>
              </>
            )}
            {buttonIsVisible ? (
              <div id="buttons">
                <Button onClick={() => setButtonIsVisible(false)}>I made the payment</Button>
              </div>
            ) : null}
          </>
        ) : (
          <div id="spinner-container">
            <Spinner animation="grow" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeDepositModal2}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
