import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faTelegram, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import orbLogo from '../../assets/images/logo-orb-explorer.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.scss';
import { validateEmail } from '../../helpers/tools';
import { sendNewsletterSignUp } from '../../services/api-service';

function Footer(): JSX.Element {
  const [email, setEmail] = useState('');
  const [sendIt, setSendIt] = useState(true);

  const send = async () => {
    const result = await sendNewsletterSignUp(email);
    if (result.success) {
      toast.success('Please, check your mailbox to confirm your subscription. Thank you!');
    } else {
      toast.error(result.message);
    }

    setSendIt(true);
    setEmail('');
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validateEmail(email)) {
        toast.error('Incorrect email');
        return;
      }

      if (sendIt) {
        setSendIt(false);
        await send();
      }
    },
    [email, sendIt]
  );

  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <h3>Join our community</h3>
            <div className="social-container">
              <a href="https://twitter.com/EverdreamSoft" target="_blank" className="social" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter as IconProp} size="2x" />
              </a>
              <a href="https://t.me/EverdreamSoft" target="_blank" className="social" rel="noreferrer">
                <FontAwesomeIcon icon={faTelegram as IconProp} size="2x" />
              </a>
              <a href="https://www.youtube.com/user/Everdreamsoft" target="_blank" className="social" rel="noreferrer">
                <FontAwesomeIcon icon={faYoutube as IconProp} size="2x" />
              </a>
              <a href="https://discord.com/invite/H4UXSaJ" target="_blank" className="social" rel="noreferrer">
                <FontAwesomeIcon icon={faDiscord as IconProp} size="2x" />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <h3>Stay updated</h3>
            <p>Join our mailing list to stay updated with our newest features.</p>
            <form onSubmit={handleSubmit}>
              <input
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
              />
              <input type="submit" className="btn-grad disabled" value="Sign up" disabled={!sendIt} />
            </form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} sm={6}>
            <img src={orbLogo} alt="Logo" />
            <p>
              Orb Explorer allows you to browse crypto assets (NFT) on multiple Blockchains such as Counterparty,
              Ethereum or Klaytn.
            </p>
          </Col>
          <Col xs={6} sm={3}>
            <h3>Site map</h3>
            <div>
              <ul>
                <li>
                  <Link to="/collections">Collections</Link>
                </li>
                <li>
                  <Link to="/transactions">Transactions</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={6} sm={3}>
            <h3>Company</h3>
            <p>
              <a href="https://everdreamsoft.com/" target="_blank" rel="noreferrer">
                EverdreamSoft
              </a>
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="ft-credit">
            <p>© {new Date().getFullYear()} EverdreamSoft</p>
          </Col>
          <Col className="ft-legal">
            <p>
              <a href="https://everdreamsoft.com/terms.php" target="_blank" rel="noreferrer">
                Terms of service
              </a>{' '}
              |{' '}
              <a href="https://everdreamsoft.com/privacypolicy.php" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
