import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import AppComponent from './components/app/app-component';
import WalletComponent from './components/wallet/wallet-component';
import CollectionsComponent from './components/collections/collections-component';
import MainMenuComponent from './components/main-menu/main-menu-component';
import PageNotFoundComponent from './components/page-not-found/page-not-found-components';
import Footer from './components/footer/footer';
import TransactionsComponent from './components/transactions/transactions-component';
import CollectionDetailsComponent from './components/collection-details/collection-details-component';
import TransactionDetailsComponent from './components/transaction-detail/transaction-detail-component';
import 'react-toastify/dist/ReactToastify.css';

export default function AppRouter(): JSX.Element {
  const location = useLocation();
  const address = new URLSearchParams(location?.search).get('address');

  return (
    <>
      <Container>
        <MainMenuComponent />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<AppComponent />} />
          <Route path="/collections" element={<CollectionsComponent />} />
          <Route path="/collection/:collectionName" element={<CollectionDetailsComponent />} />
          <Route path="/collection" element={<CollectionDetailsComponent />} />
          <Route path="/transactions/:txId" element={<TransactionDetailsComponent />} />
          <Route path="/transactions" element={<TransactionsComponent />} />
          <Route path="/wallet/:selectedAddress" element={<WalletComponent />} />
          <Route path="/address" element={<Navigate replace to={address ? `/wallet/${address}` : '/collections'} />} />
          <Route path="*" element={<PageNotFoundComponent />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
}
