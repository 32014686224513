import React from 'react';
import Tilt from 'react-parallax-tilt';
import gnome from '../../assets/images/gnome-404.png';

export default function PageNotFoundComponent(): JSX.Element {
  return (
    <div className="d-flex justify-content-center text-center">
      <div className="flex-column">
        <div className="p-2">
          <Tilt
            className="parallax-effect-img"
            tiltMaxAngleX={10}
            tiltMaxAngleY={10}
            perspective={1000}
            transitionSpeed={1000}
            scale={1}
          >
            <img src={gnome} loading="lazy" alt="" />
          </Tilt>
        </div>
        <h2 className="p-2">We can&apos;t find that page</h2>
        <p>
          We&apos;re fairly sure that page used to be here but seems to have gone missing. We do apologize on its
          behalf.
        </p>
      </div>
    </div>
  );
}
