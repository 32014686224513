import React from 'react';
import { Button } from 'react-bootstrap';

type FilterControlProps = {
  onFilter: (text: string) => void;
};

export default function FilterControl({ onFilter }: FilterControlProps): JSX.Element {
  return (
    <div className="d-flex search-input">
      <input
        className="form-control"
        onChange={(e) => {
          onFilter(e.target.value);
        }}
        placeholder="Filter…"
      />
      <Button type="button" className="btn btn-primary">
        <i className="icon-search4" />
      </Button>
    </div>
  );
}
