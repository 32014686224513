import MetaMaskService from '../services/wallet/meta-mask-service';
import Web3Service from '../services/wallet/web3-service';

export default class WalletHelper {
  public static async getEthereumWallet(): Promise<string> {
    let result = '';
    const metamaskService = new MetaMaskService();
    if (metamaskService.isWalletInstalled()) {
      result = await metamaskService.getAccounts();
    } else {
      const web3Service = new Web3Service();
      if (web3Service.isWalletInstalled()) {
        result = await web3Service.getAccounts();
      }
    }

    return result;
  }
}
