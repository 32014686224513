import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

export default class FirstOasisService extends BaseWalletService implements WalletService {
  init = async (
    newChainHandler?: (chainId: number) => void,
    newNetworkHandler?: (networkId: number) => void,
    newAccountHandler?: (newAccounts: string | string[]) => void
  ) => {
    super.init(undefined, undefined, newAccountHandler);
  };

  connect = async (firstOasisWallet: string): Promise<void> => {
    if (firstOasisWallet) {
      this.handleNewAccounts(firstOasisWallet);
    }
  };

  isWalletInstalled = (): boolean => {
    return true;
  };

  getAccounts = async (): Promise<string> => {
    throw new Error('Method not implemented.');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  personalSign = (message: string, password?: string): Promise<string> => {
    throw new Error('Method not implemented.');
  };

  getName = (): string => {
    return 'FirstOasis';
  };
}
