/* eslint-disable no-console */
import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import Blockchain from '../../enums/blockchain-enum';
import DepositModal1 from '../../modals/deposit-modal-1/deposit-modal-1-component';
import DepositModal2 from '../../modals/deposit-modal-2/deposit-modal-2-component';
import WithdrawModal1 from '../../modals/withdraw-modal-1/withdraw-modal-1-component';
import WithdrawModal2 from '../../modals/withdraw-modal-2/withdraw-modal-2-component';
import WithdrawModal3 from '../../modals/withdraw-modal-3/withdraw-modal-3-component';
import WithdrawModal4 from '../../modals/withdraw-modal-4/withdraw-modal-4-component';
import WithdrawModal5 from '../../modals/withdraw-modal-5/withdraw-modal-5-component';
import { DepositExecutorResponse } from '../../models/deposit-executor-response-model';
import UserAddresses from '../../models/user-addresses-model';
import { WalletOrb } from '../../models/wallet-orb-model';
import WithdrawResponse from '../../models/withdraw-response-model';
import { postWithdraw, getDepositAddresses, postWithdrawVerify } from '../../services/api-service';

export default function WithdrawComponent(params: {
  orb: WalletOrb;
  userAddresses: UserAddresses;
  selectedAddress: string;
}): JSX.Element {
  const { orb, userAddresses, selectedAddress } = params;

  const { CancelToken } = axios;

  const [userHasEnoughBitCrystals, setUserHasEnoughBitCrystals] = useState<boolean>();
  const [verifyWithdrawError, setVerifyWithdrawError] = useState<boolean>(false);
  const [verifyWithdrawErrorMessage, setVerifyWithdrawErrorMessage] = useState<string>();
  const [withdrawAddress, setWithdrawAddress] = useState<string>();
  const [withdrawModal1Visibility, setWithdrawModal1Visibility] = useState(false);
  const [withdrawModal2Visibility, setWithdrawModal2Visibility] = useState(false);
  const [withdrawModal3Visibility, setWithdrawModal3Visibility] = useState(false);
  const [withdrawModal4Visibility, setWithdrawModal4Visibility] = useState(false);
  const [withdrawModal5Visibility, setWithdrawModal5Visibility] = useState(false);
  const [withdrawBlockchain, setWithdrawBlockchain] = useState<
    Blockchain.Counterparty | Blockchain.Ethereum | undefined
  >();
  const [withdrawError, setWithdrawError] = useState<boolean>(false);
  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState<string>();
  const [withdrawInProgress, setWithdrawInProgress] = useState<boolean>(false);
  const [withdrawOrb, setWithdrawOrb] = useState<WalletOrb | undefined>(orb);
  const [withdrawVerified, setWithdrawVerified] = useState<boolean>(false);
  const [withdrawTxHash, setWithdrawTxHash] = useState<string>();
  const [depositAddress, setDepositAddress] = useState<string>();
  const [depositBlockchain, setDepositBlockchain] = useState<
    Blockchain.Counterparty | Blockchain.Ethereum | undefined
  >();
  const [depositInterval, setDepositInterval] = useState<NodeJS.Timeout>();
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const [depositModal1Visibility, setDepositModal1Visibility] = useState(false);
  const [depositModal2Visibility, setDepositModal2Visibility] = useState(false);
  const [gasAvailable, setGasAvailable] = useState<number>();
  const [gasMinimumRequired, setGasMinimumRequired] = useState<number>(0);
  const [gasRequired, setGasRequired] = useState<number>();
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(CancelToken.source());

  function cancelHttpRequest() {
    cancelTokenSource.cancel('Operation canceled by the user.');
    setCancelTokenSource(CancelToken.source());
  }

  function clearDepositInterval() {
    if (depositInterval) {
      clearInterval(depositInterval);
    }
  }

  const returnToWithdrawModal1 = () => {
    cancelHttpRequest();
    setWithdrawBlockchain(undefined);
    setWithdrawAddress(undefined);
    setWithdrawVerified(false);
    setVerifyWithdrawError(false);
    setWithdrawModal1Visibility(true);
    setWithdrawModal2Visibility(false);
  };

  const returnToWithdrawModal2 = () => {
    clearDepositInterval();
    cancelHttpRequest();
    setDepositModal1Visibility(false);
    setWithdrawModal2Visibility(true);
    setWithdrawModal3Visibility(false);
  };

  const returnToWithdrawModal3 = () => {
    cancelHttpRequest();
    setWithdrawModal3Visibility(true);
    setWithdrawModal4Visibility(false);
  };

  const openWithdrawModal3 = () => {
    setWithdrawModal3Visibility(true);
    setWithdrawModal2Visibility(false);
  };

  const openWithdrawModal4 = () => {
    setWithdrawModal4Visibility(true);
    setWithdrawModal3Visibility(false);
  };

  const openWithdrawModal5 = () => {
    setWithdrawModal5Visibility(true);
    setWithdrawModal4Visibility(false);
  };

  const openDepositModal1 = () => {
    setDepositModal1Visibility(true);
    setWithdrawModal2Visibility(false);
  };

  const closeModal = () => {
    setWithdrawOrb(undefined);
    setWithdrawModal1Visibility(false);
    cancelHttpRequest();
    setWithdrawBlockchain(undefined);
    setWithdrawAddress(undefined);
    setWithdrawVerified(false);
    setVerifyWithdrawError(false);
    setWithdrawModal2Visibility(false);
    setWithdrawModal3Visibility(false);
    setWithdrawModal4Visibility(false);
    setWithdrawModal5Visibility(false);
    setWithdrawInProgress(false);
    clearDepositInterval();
    setDepositModal1Visibility(false);
    setDepositModal2Visibility(false);
  };

  const getAddresses = async (blockchain: string): Promise<DepositExecutorResponse> => {
    return getDepositAddresses(userAddresses.counterparty, cancelTokenSource)
      .then((response: DepositExecutorResponse) => {
        setDepositLoading(false);
        response.data.user.deposit.forEach((address) => {
          if (address.blockchain === blockchain) {
            setDepositAddress(address.address);
          }
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        setDepositLoading(false);
        console.error('error', error);
        return Promise.reject(error);
      });
  };

  const openDepositModal2 = (blockchain: Blockchain.Counterparty | Blockchain.Ethereum) => {
    setDepositBlockchain(blockchain);
    setDepositLoading(true);
    getAddresses(blockchain.toLocaleLowerCase()).then(() => {
      setDepositModal2Visibility(true);
      setDepositModal1Visibility(false);
    });
    const interval = setInterval(() => {
      setDepositLoading(true);
      getAddresses(blockchain.toLocaleLowerCase());
    }, 5000);
    setDepositInterval(interval);
    return () => {
      clearDepositInterval();
      setDepositLoading(false);
    };
  };

  const withdraw = async (signedMessage: string) => {
    setWithdrawInProgress(true);
    if (withdrawAddress && withdrawBlockchain && withdrawOrb) {
      await postWithdraw(
        withdrawAddress,
        withdrawOrb.asset.id,
        withdrawBlockchain.toLocaleLowerCase(),
        signedMessage,
        userAddresses.ethereum,
        withdrawAddress,
        1,
        cancelTokenSource
      )
        .then((response: WithdrawResponse) => {
          setWithdrawInProgress(false);
          if (response.data.message === 'success') {
            setWithdrawTxHash(response.data.transactions.receive.tx[0].txHash);
            openWithdrawModal5();
          } else {
            setWithdrawError(true);
            setWithdrawErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  function checkIfUserHasEnoughBitCrystals(): boolean {
    if (gasAvailable && gasRequired) {
      if (gasAvailable >= gasRequired) {
        return true;
      }
    }
    return false;
  }

  async function verifyWithdraw(blockchain: Blockchain.Counterparty | Blockchain.Ethereum): Promise<void> {
    if (withdrawOrb) {
      await postWithdrawVerify(
        selectedAddress,
        withdrawOrb.asset.id,
        blockchain.toLocaleLowerCase(),
        userAddresses.ethereum,
        1,
        cancelTokenSource
      )
        .then((response: WithdrawResponse) => {
          if (response.data.query.balance !== 'pass') {
            if (!response.data.query.balancePay && response.data.query.balancePool) {
              setGasAvailable(response.data.gas.adapted.available);
              setGasRequired(response.data.gas.adapted.required);
            }
            if (!response.data.query.balanceSender) {
              setVerifyWithdrawError(true);
              setVerifyWithdrawErrorMessage('You do not own this Orb, therefore you cannot withdraw it, sorry.');
            }
            if (!response.data.query.balancePool) {
              setVerifyWithdrawError(true);
              setVerifyWithdrawErrorMessage(
                'This Orb is temporarily out of stock. Please, try later. We apologize for the inconvenience.'
              );
            }
          } else if (response.data.gas) {
            setGasAvailable(response.data.gas.adapted.available);
            setGasRequired(response.data.gas.adapted.required);
          }
          setWithdrawVerified(true);
        })
        .catch((error) => {
          console.error('error', error);
          if (error.message !== 'Operation canceled by the user.') {
            setVerifyWithdrawError(true);
            setVerifyWithdrawErrorMessage(
              'The server encountered an unexpected error, sorry. Please, contact our support.'
            );
            setWithdrawVerified(true);
          }
        });
    }
  }

  const selectWithdrawBlockchain = async (blockchain: Blockchain.Counterparty | Blockchain.Ethereum) => {
    setWithdrawBlockchain(blockchain);
    if (blockchain === Blockchain.Counterparty) {
      setWithdrawAddress(userAddresses.counterparty);
    } else if (blockchain === Blockchain.Ethereum) {
      setWithdrawAddress(userAddresses.ethereum);
    }
    setWithdrawModal2Visibility(true);
    setWithdrawModal1Visibility(false);
    await verifyWithdraw(blockchain);
  };

  useEffect(() => {
    if (gasAvailable !== undefined && gasRequired !== undefined) {
      setUserHasEnoughBitCrystals(checkIfUserHasEnoughBitCrystals());
      setGasMinimumRequired(gasRequired - gasAvailable);
    }
  }, [gasAvailable, gasRequired]);

  useEffect(() => {
    setWithdrawModal1Visibility(!!withdrawOrb);
  }, []);

  return (
    <>
      <WithdrawModal1
        closeWithdrawModal1={closeModal}
        orb={withdrawOrb}
        selectWithdrawBlockchain={selectWithdrawBlockchain}
        withdrawModal1Visibility={withdrawModal1Visibility}
      />
      <WithdrawModal2
        closeWithdrawModal2={closeModal}
        gasAvailable={gasAvailable}
        gasMinimumRequired={gasMinimumRequired}
        gasRequired={gasRequired}
        openDepositModal1={openDepositModal1}
        openWithdrawModal3={openWithdrawModal3}
        orb={withdrawOrb}
        returnToWithdrawModal1={returnToWithdrawModal1}
        userHasEnoughBitCrystals={userHasEnoughBitCrystals}
        verifyWithdrawError={verifyWithdrawError}
        verifyWithdrawErrorMessage={verifyWithdrawErrorMessage}
        withdrawBlockchain={withdrawBlockchain}
        withdrawModal2Visibility={withdrawModal2Visibility}
        withdrawVerified={withdrawVerified}
      />
      <WithdrawModal3
        closeWithdrawModal3={closeModal}
        openWithdrawModal4={openWithdrawModal4}
        orb={withdrawOrb}
        returnToWithdrawModal2={returnToWithdrawModal2}
        withdrawAddress={withdrawAddress}
        withdrawBlockchain={withdrawBlockchain}
        withdrawModal3Visibility={withdrawModal3Visibility}
      />
      <WithdrawModal4
        closeWithdrawModal4={closeModal}
        gasRequired={gasRequired}
        orb={withdrawOrb}
        returnToWithdrawModal3={returnToWithdrawModal3}
        userAddresses={userAddresses}
        withdraw={withdraw}
        withdrawAddress={withdrawAddress}
        withdrawBlockchain={withdrawBlockchain}
        withdrawError={withdrawError}
        withdrawErrorMessage={withdrawErrorMessage}
        withdrawInProgress={withdrawInProgress}
        withdrawModal4Visibility={withdrawModal4Visibility}
        withdrawOrb={withdrawOrb}
      />
      <WithdrawModal5
        closeWithdrawModal5={closeModal}
        orb={withdrawOrb}
        withdrawBlockchain={withdrawBlockchain}
        withdrawModal5Visibility={withdrawModal5Visibility}
        withdrawTxHash={withdrawTxHash}
      />
      <DepositModal1
        closeDepositModal1={closeModal}
        depositModal1Visibility={depositModal1Visibility}
        depositLoading={depositLoading}
        gasMinimumRequired={gasMinimumRequired}
        openDepositModal2={openDepositModal2}
        returnToWithdrawModal2={returnToWithdrawModal2}
      />
      <DepositModal2
        closeDepositModal2={closeModal}
        depositAddress={depositAddress}
        depositBlockchain={depositBlockchain}
        depositModal2Visibility={depositModal2Visibility}
        gasMinimumRequired={gasMinimumRequired}
      />
    </>
  );
}
