import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './clickable-card-component.scss';

type ClickableCardComponentProps = {
  link: string;
  image: string;
  title: string;
  text: string;
  buttonText: string;
  isInternalRouting: boolean;
};

export default function ClickableCardComponent({
  link,
  image,
  title,
  text,
  buttonText,
  isInternalRouting,
}: ClickableCardComponentProps): JSX.Element {
  return (
    <div className="d-flex w-100">
      {isInternalRouting ? (
        <Link className="clickable-card flex-fill" to={link}>
          <Card className="cards h-100">
            <div className="img-container">
              <Card.Img variant="top" loading="lazy" src={image} />
            </div>

            <Card.Body>
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="flex-grow-1">
                  <h5>{title}</h5>
                  <hr />
                  <Card.Text>{text}</Card.Text>
                </div>
                <div className="button mt-2">{buttonText}</div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      ) : (
        <a className="clickable-card" href={link} target="_blank" rel="noreferrer">
          <Card className="cards">
            <Card.Img variant="top" loading="lazy" src={image} />
            <Card.Body>
              <h5>{title}</h5>
              <hr />
              <Card.Text>{text}</Card.Text>
              <div className="button">{buttonText}</div>
            </Card.Body>
          </Card>
        </a>
      )}
    </div>
  );
}
