import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Blockchain from '../../enums/blockchain-enum';
import { WalletOrb } from '../../models/wallet-orb-model';
import { capitalizeFirstLetter } from '../../helpers/tools';
import './withdraw-modal-1-component.scss';

interface IWithdrawModalParams {
  closeWithdrawModal1: MouseEventHandler<HTMLButtonElement>;
  orb: WalletOrb | undefined;
  selectWithdrawBlockchain: (blockchain: Blockchain.Counterparty | Blockchain.Ethereum) => void;
  withdrawModal1Visibility: boolean;
}

export default function WithdrawModal1(props: IWithdrawModalParams): JSX.Element {
  const [isOpenState, setOpenState] = useState(false);
  const { closeWithdrawModal1, orb, selectWithdrawBlockchain, withdrawModal1Visibility } = props;

  useEffect(() => {
    setOpenState(withdrawModal1Visibility);
  }, [withdrawModal1Visibility]);

  return (
    <Modal
      id="withdraw-modal-1"
      show={isOpenState}
      onHide={() => closeWithdrawModal1}
      animation={false}
      centered
      backdropClassName="modal-backdrop"
    >
      <Modal.Header>
        <Modal.Title>Withdrawal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="orb-image">
          <img
            src={orb?.asset.image}
            className="w-100 h-100 p-1 pb-3 d-flex justify-content-center"
            alt="Orb to withdraw"
          />
        </div>
        <p className="text-center">
          You are going to withdraw this Orb on the blockchain of your choice. Choose the blockchain.
        </p>
        <div id="buttons">
          <Button onClick={() => selectWithdrawBlockchain(Blockchain.Counterparty)}>
            {capitalizeFirstLetter(Blockchain.Counterparty)}
          </Button>
          <Button onClick={() => selectWithdrawBlockchain(Blockchain.Ethereum)}>
            {capitalizeFirstLetter(Blockchain.Ethereum)}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeWithdrawModal1}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
