import React from 'react';
import Select, { MultiValue, StylesConfig } from 'react-select';
import { SelectOption } from '../../models/select-option-model';
import './multi-select-component.scss';

interface MultiSelectComponentProps {
  options: SelectOption[];
  onChange: (value: MultiValue<SelectOption>) => void;
  values: string[];
  placeholder: string;
}

type IsMulti = true;

export type MultiSelectKeys = {
  label: string;
  value: string;
};

export default function MultiSelectComponent({
  options,
  onChange,
  values,
  placeholder,
}: MultiSelectComponentProps): JSX.Element {
  const customStyles: StylesConfig<SelectOption, IsMulti> = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
      transition: '.2s ease-in-out',
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  const formattedValues = options.filter((option) => values.includes(option.value));

  return (
    <Select<SelectOption, IsMulti>
      isMulti
      styles={customStyles}
      options={options}
      onChange={onChange}
      isSearchable={false}
      value={formattedValues}
      placeholder={placeholder}
      classNamePrefix="react-multiselect"
      components={{
        IndicatorSeparator: () => null,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#32a0fc',
          primary50: 'rgba(0,0,0,0.20)',
          primary25: 'rgba(0,0,0,0.10)',
          dangerLight: 'transparent',
        },
      })}
    />
  );
}
