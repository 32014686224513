import React from 'react';
import { Button, CloseButton, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BlockchainNetworkId from '../../enums/blockchin-network-id';
import Wallet from '../../models/wallet-model';
import WalletService from '../../services/wallet/wallet-service';
import { extractAccount, getNetworkLongName, getNetworkName, shortenText } from '../../helpers/tools';
import './wallet-drop-down-control.scss';

const isInsideCasaTookan = !!window.webXCP;

async function connectWallet(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  service: WalletService
): Promise<void> {
  e.preventDefault();
  await service.connect();
}

async function disconnectWallet(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  service: WalletService
): Promise<void> {
  e.preventDefault();
  await service.disconnect();
}

function goToExternalUrl(url: string): void {
  window.open(url, '_blank');
}

function DropdownItemBody(params: { chain?: string | number }) {
  const { chain } = params;
  return (
    <div className="d-flex flex-column text-body mt-2">
      <div>
        <span className={`d-inline px-3 py-2 blockchain-img-${getNetworkName(chain)}`}>{}</span>
        <span className="d-inline text-capitalize font-weight-bold">{getNetworkName(chain)}</span>
      </div>
      <div>
        <span className="float-end font-weight-bold">Wallet is not installed</span>
      </div>
    </div>
  );
}

export default function WalletDropDown(params: { walletService: WalletService; wallet: Wallet }): JSX.Element {
  const initParams = params;
  const { isInstalled, address, chain } = initParams.wallet;
  const service = initParams.walletService;
  const key = service.getName();

  if (isInstalled) {
    return (
      <Dropdown.Item key={key} as={Link} to={address ? `/wallet/${address}` : '/collections'}>
        <Row>
          <div>
            <i className={`d-inline px-3 py-2 blockchain-img-${getNetworkName(chain)}`}>{}</i>
            <span className="d-inline text-capitalize font-weight-bold">{getNetworkLongName(chain)}</span>
            {!isInsideCasaTookan && address && (
              <CloseButton className="btn-sm float-end p-0" onClick={(e) => disconnectWallet(e, service)} />
            )}
          </div>
        </Row>
        <Row>
          {address ? (
            <div className="d-flex justify-content-end">
              {!isInsideCasaTookan && <span className={`d-inline px-2 mx-2 wallet-img-${service.getName()}`} />}
              <span className="d-inline">{shortenText(extractAccount(address), 12)}</span>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <Button onClick={(e) => connectWallet(e, service)} className="btn-sm connect-button">
                <span>Connect with {key}</span>
              </Button>
            </div>
          )}
        </Row>
      </Dropdown.Item>
    );
  }

  return chain === BlockchainNetworkId.Counterparty ? (
    <Dropdown.Item key={key}>
      <DropdownItemBody chain={chain} />
    </Dropdown.Item>
  ) : (
    <Dropdown.Item key={key} onClick={() => goToExternalUrl(service.getDownloadWalletPath())}>
      <DropdownItemBody chain={chain} />
    </Dropdown.Item>
  );
}
