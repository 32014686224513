import React from 'react';
import './home-component.scss';
import bigOeLogo from '../../assets/images/big_oe_logo.png';

function HomeComponent(): JSX.Element {
  return (
    <div className="banner">
      <div className="ban-content">
        <img src={bigOeLogo} alt="Orb Explorer" />
        <h3>Your NFT Browser</h3>
        <p>Ethereum, Counterparty, Kusama, Klaytn</p>
      </div>
    </div>
  );
}

export default HomeComponent;
