import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function TransactionDetailsPlaceholder(): JSX.Element {
  return (
    <Row>
      <Col xs="12" md="4" lg="4" className="placeholder-glow">
        <div className="placeholder" style={{ width: '100%', aspectRatio: '1' }} />
      </Col>
      <Col xs="12" md="8" lg="8" className="placeholder-glow">
        <div className="placeholder mb-2" style={{ width: '100%', height: 40 }} />
        <div className="placeholder mb-4" style={{ width: '100%', height: 25 }} />
        <div className="placeholder" style={{ width: '100%', height: 400 }} />
      </Col>
    </Row>
  );
}
