import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Blockchain from '../../enums/blockchain-enum';
import { WalletOrb } from '../../models/wallet-orb-model';
import { capitalizeFirstLetter } from '../../helpers/tools';
import './withdraw-modal-3-component.scss';

interface IWithdrawModalParams {
  closeWithdrawModal3: MouseEventHandler<HTMLButtonElement>;
  openWithdrawModal4: () => void;
  orb: WalletOrb | undefined;
  returnToWithdrawModal2: () => void;
  withdrawAddress: string | undefined;
  withdrawBlockchain: Blockchain.Counterparty | Blockchain.Ethereum | undefined;
  withdrawModal3Visibility: boolean;
}

export default function WithdrawModal3(props: IWithdrawModalParams): JSX.Element {
  const [isOpenState, setOpenState] = useState(false);
  const {
    closeWithdrawModal3,
    openWithdrawModal4,
    orb,
    returnToWithdrawModal2,
    withdrawAddress,
    withdrawBlockchain,
    withdrawModal3Visibility,
  } = props;

  useEffect(() => {
    setOpenState(withdrawModal3Visibility);
  }, [withdrawModal3Visibility]);

  return (
    <Modal
      id="withdraw-modal-3"
      show={isOpenState}
      onHide={() => closeWithdrawModal3}
      animation={false}
      centered
      backdropClassName="modal-backdrop"
    >
      <Modal.Header>
        <Modal.Title>Withdrawal on {capitalizeFirstLetter(withdrawBlockchain)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="orb-image">
          <img
            src={orb?.asset.image}
            className="w-100 h-100 p-1 pb-3 d-flex justify-content-center"
            alt="Orb to withdraw"
          />
        </div>
        <p>You are going to withdraw this Orb on the following blockchain address:</p>
        <h5>{withdrawAddress}</h5>
        <div id="buttons">
          <Button
            onClick={() => {
              openWithdrawModal4();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => returnToWithdrawModal2()}>
          Back
        </Button>
        <Button variant="secondary" onClick={closeWithdrawModal3}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
