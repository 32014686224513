import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

const { webXCP } = window;

export default class WebXcpService extends BaseWalletService implements WalletService {
  getAccounts = async (): Promise<string> => {
    let result = '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function callback(err: any, bitcoinAddress: string) {
      if (err) {
        return `err: ${err.message}`;
      }

      result = bitcoinAddress;
      return bitcoinAddress;
    }

    webXCP?.getAccounts(null, callback);

    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 500));

    return result;
  };

  init = async (
    newChainHandler?: (chainId: number) => void,
    newNetworkHandler?: (networkId: number) => void,
    newAccountHandler?: (newAccounts: string | string[]) => void
  ) => {
    super.init(undefined, undefined, newAccountHandler);
  };

  isWalletInstalled = () => Boolean(webXCP);

  connect = async () => {
    const account = await this.getAccounts();
    this.handleNewAccounts(account);
  };

  getDownloadWalletPath = (): string => {
    return '';
  };

  getName = (): string => {
    return 'Counterparty';
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  personalSign = async (message: string, password?: string): Promise<string> => {
    throw new Error('Method not implemented.');
  };
}
