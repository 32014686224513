/* eslint-disable no-console */
import { CancelTokenSource } from 'axios';
import qs from 'qs';
import {
  CAROUSELS,
  COLLECTIONS_URL,
  COLLECTION_DETAILS_URL,
  TRANSACTIONS_URL,
  TRANSACTION_URL,
  TRANSACTION_FOR_COLLECTION_URL,
  USER_ADDRESSES,
  WALLET_BALANCE,
  WITHDRAW_VERIFY_URL,
  WITHDRAW_URL,
  DEPOSIT_URL,
  balorApi,
  tobleroneApi,
  spellsOfGenesisApi,
  newsletterApi,
} from '../apis';
import { CarouselItem } from '../models/carousel-item-model';
import { CollectionDetails } from '../models/collection-details-model';
import Collections from '../models/collections-model';
import { DepositExecutorResponse } from '../models/deposit-executor-response-model';
import NewsletterResponse from '../models/newsletter-response-model';
import { OrbDetails } from '../models/orb-details-model';
import { Transactions } from '../models/transactions-model';
import { User } from '../models/user-model';
import { WalletBalance } from '../models/wallet-balance-model';
import WithdrawResponse from '../models/withdraw-response-model';

async function getCollectionsAsync(cancelToken: CancelTokenSource): Promise<Collections> {
  try {
    const response = await balorApi.get(COLLECTIONS_URL, { cancelToken: cancelToken.token });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getTransactionsAsync(
  cancelToken: CancelTokenSource,
  collectionName?: string,
  address?: string
): Promise<Transactions> {
  let url = collectionName ? TRANSACTION_FOR_COLLECTION_URL + collectionName : TRANSACTIONS_URL;
  url = url.replace('{address}', address ? `/${address}` : '');
  try {
    const response = await balorApi.get(url, { cancelToken: cancelToken.token });
    const result: Transactions = response.data;
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getCollectionDetailsAsync(
  collectionName: string,
  cancelToken: CancelTokenSource
): Promise<CollectionDetails> {
  try {
    const response = await balorApi.get(COLLECTION_DETAILS_URL + collectionName, { cancelToken: cancelToken.token });

    // Fix for the API inconsistency
    if (!response.data) {
      return {} as CollectionDetails;
    }

    // filtering the collection from the api from incorrect items
    // TODO: remove that logic when it will be resolved on the server
    // TODO: the task to remove incorrect data on the server - ORBEXP-489
    const incorrectImageUrl =
      'https://metadata.spellsofgenesis.com/0x9227a3d959654c8004fa77dffc380ec40880fff6/image/0.jpg';

    const result = response.data;
    const keys = Object.keys(result.data.assets);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fixedAssets: any = {};
    keys.forEach((assetId) => {
      if (result.data.assets[assetId].imageUrl !== incorrectImageUrl) {
        fixedAssets[assetId] = result.data.assets[assetId];
        if (fixedAssets[assetId].contract) {
          if (fixedAssets[assetId].contracts) {
            throw new Error('Incorrect API data!');
          }

          fixedAssets[assetId].contracts = [result.data.assets[assetId].contract];
        }
      }
    });

    result.data.assets = fixedAssets;

    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getUserAddressesAsync(address: string, cancelToken: CancelTokenSource): Promise<User> {
  try {
    const response = await balorApi.get(USER_ADDRESSES + address, { cancelToken: cancelToken.token });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getWalletBalanceAsync(address: string, cancelToken: CancelTokenSource): Promise<WalletBalance> {
  try {
    const response = await balorApi.get(`${WALLET_BALANCE}${address}?purge=true`, { cancelToken: cancelToken.token });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getCarouselsAsync(cancelToken: CancelTokenSource): Promise<CarouselItem[]> {
  try {
    const response = await balorApi.get(CAROUSELS, { cancelToken: cancelToken.token });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function postWithdrawVerify(
  address: string,
  asset: string,
  blockchain: string,
  signerAddress: string,
  quantity: number,
  source: CancelTokenSource
): Promise<WithdrawResponse> {
  return balorApi
    .post(
      WITHDRAW_VERIFY_URL,
      {
        address,
        asset,
        blockchain,
        signerAddress,
        quantity,
      },
      {
        cancelToken: source.token,
      }
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error('error', error);
      return Promise.reject(error);
    });
}

async function postWithdraw(
  address: string,
  asset: string,
  blockchain: string,
  signature: string,
  signerAddress: string,
  toAddress: string,
  quantity: number,
  source: CancelTokenSource
): Promise<WithdrawResponse> {
  return balorApi
    .post(
      WITHDRAW_URL,
      {
        address,
        asset,
        blockchain,
        signature,
        signerAddress,
        toAddress,
        quantity,
      },
      {
        cancelToken: source.token,
      }
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error('error', error);
      return Promise.reject(error);
    });
}

async function getDepositAddresses(
  counterpartyAddress: string,
  source: CancelTokenSource
): Promise<DepositExecutorResponse> {
  return tobleroneApi
    .get(DEPOSIT_URL + counterpartyAddress, {
      cancelToken: source.token,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.error('error', error);
      return Promise.reject(error);
    });
}

async function getCardMetadataAsync(cardId: string, cancelToken: CancelTokenSource): Promise<OrbDetails> {
  try {
    const response = await spellsOfGenesisApi.get(`?token=${cardId}`, { cancelToken: cancelToken.token });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function sendNewsletterSignUp(email: string): Promise<NewsletterResponse> {
  try {
    const data = qs.stringify({ EMAIL: email, email_address_check: '', locale: 'en' });
    const response = await newsletterApi.post('?isAjax=1', data);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

async function getTransactionAsync(txId: string, cancelToken: CancelTokenSource): Promise<Transactions> {
  try {
    const response = await balorApi.get(`${TRANSACTION_URL}/${txId}`, { cancelToken: cancelToken.token });
    const result: Transactions = response.data;
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export {
  getCollectionsAsync,
  getTransactionsAsync,
  getCollectionDetailsAsync,
  getUserAddressesAsync,
  getWalletBalanceAsync,
  getCarouselsAsync,
  postWithdrawVerify,
  postWithdraw,
  getDepositAddresses,
  getCardMetadataAsync,
  sendNewsletterSignUp,
  getTransactionAsync,
};
