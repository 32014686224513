import React, { useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';
import { Transactions } from '../../models/transactions-model';
import './transaction-list-component.scss';
import NoCollectiblesComponent from '../no-collectibles/no-collectibles-components';
import noImageFound from '../../assets/images/no-image-found.jpg';
import Blockchain from '../../enums/blockchain-enum';
import SelectComponent from '../select/select-component';
import MultiSelectComponent from '../multi-select/multi-select-component';
import NoCollectiblesType from '../../enums/no-collectibles-type-enum';
import {
  capitalizeFirstLetter,
  convertDate,
  extractContractOrName,
  removeDuplicates,
  sortArray,
} from '../../helpers/tools';
import TransactionListItemComponent from '../transaction-list-item/transaction-list-item-component';
import TransactionType from '../../enums/transaction-type';
import Filter from '../../models/filter-model';
import FilterControl from '../../controls/filter/filter-control';
import SortComponent, { SortingName, SortingValue } from '../sort/sort-component';
import { SelectOption } from '../../models/select-option-model';
import { SelectColors } from '../../enums/select-colors-enum';
import TransactionItem from '../../models/transaction-item-model';

type Pagination = {
  pageIndex: number;
  pageSize: number;
  pageCount?: number;
};

type TransactionListComponentProps = {
  transactions: Transactions;
  hideBlockchainFilter?: boolean;
};

type TransactionItemKeys = keyof TransactionItem;

export default function TransactionListComponent({
  transactions,
  hideBlockchainFilter,
}: TransactionListComponentProps): JSX.Element {
  const [summaryText, setSummaryText] = useState('');
  const { selectedAddress } = useParams();
  const [pagination, setPagination] = useState<Pagination>({ pageIndex: 0, pageSize: 10 });
  const [filters, setFilters] = useState<Filter[]>([]);
  const [sorting, setSorting] = useState<SortingValue>();

  const excludedFromFilter = ['explorer', 'image', 'transactionType'];
  const sortingFields: SortingName[] = [
    { caption: 'Blockchain', sortingField: 'blockchain' },
    { caption: 'Collection', sortingField: 'collection' },
    { caption: 'Contract', sortingField: 'contract' },
    { caption: 'From', sortingField: 'source' },
    { caption: 'To', sortingField: 'destination' },
    { caption: 'Quantity', sortingField: 'quantity' },
    { caption: 'Time', sortingField: 'time' },
  ];

  const data = useMemo<TransactionItem[]>(
    () =>
      transactions?.data?.map((t) => {
        return {
          blockchain: t.blockchain,
          collection: t.orbs?.[0]?.collection?.name ?? '[Missing name]',
          contract: extractContractOrName(t),
          destination: t.destination,
          destinationUsername: t.destination_user?.spellsofgenesis.username ?? '',
          explorer: transactions?.meta?.activeBlockchains
            ? transactions?.meta?.activeBlockchains[t.blockchain]?.explorerTx
            : '',
          image: t.orbs?.[0]?.imageUrl?.includes('cryptokitties')
            ? noImageFound
            : null ?? t.orbs?.[0]?.imageUrl ?? t.orbs?.[0]?.imgURL ?? t.orbs?.[0]?.fallbackImageUrl ?? noImageFound,
          quantity: +(t?.adaptedQuantity != null ? t.adaptedQuantity : t.quantity),
          source: t.source,
          sourceUsername:
            t.source === '0x0000000000000000000000000000000000000000'
              ? 'issuance'
              : null ?? t.source_user?.spellsofgenesis.username ?? '',
          time: convertDate(t.timestamp),
          txId: t.txId,
          transactionType: t.source === selectedAddress ? TransactionType.Debit : TransactionType.Credit,
        };
      }),
    []
  );

  const filteredData = useMemo<TransactionItem[]>(() => {
    let result = [...data];

    filters
      .filter((selectFilters) => selectFilters.id !== 'textFilter')
      .forEach((filter) => {
        result = result.filter(
          (transactionItem: TransactionItem) =>
            filter.values.length === 0 ||
            filter.values.includes(transactionItem[filter.id as TransactionItemKeys] as TransactionItemKeys)
        );
      });

    const textFilter = filters.find((x) => x.id === 'textFilter');
    if (textFilter) {
      const keys = Object.keys(new TransactionItem());
      const fieldValue = textFilter.values && textFilter.values[0].toLowerCase();
      result = result.filter((field) =>
        keys
          .filter((k) => !excludedFromFilter.includes(k))
          .some((key) => field[key as TransactionItemKeys]?.toString().toLowerCase().includes(fieldValue))
      );
    }

    if (sorting?.sortingField) {
      result = sortArray<TransactionItem>(result, sorting.sortingField, sorting.sorting);
    }

    return result;
  }, [data, filters, sorting]);

  const pagedData = useMemo(
    () =>
      filteredData.slice(pagination.pageIndex * pagination.pageSize, (pagination.pageIndex + 1) * pagination.pageSize),
    [filteredData, pagination]
  );

  const blockchains = [Blockchain.Counterparty, Blockchain.Ethereum, Blockchain.Klaytn];
  const blockchainOptions = useMemo(
    () =>
      blockchains.map((m) => {
        return {
          label: capitalizeFirstLetter(m),
          value: m,
        };
      }),
    [blockchains]
  );

  const collectionOptions = useMemo(
    () =>
      removeDuplicates(data.map((x) => x.collection))
        .sort()
        .map((m) => {
          return {
            label: m,
            value: m,
          };
        }),
    [data]
  );

  const onFilter = (values: MultiValue<SelectOption>, filterId: string) => {
    const filter = filters.find((x) => x.id === filterId);
    const newValues = values.map((value) => value.value);

    if (filter) {
      filter.values = newValues;
    } else {
      filters.push({ id: filterId, values: newValues });
    }

    setFilters([...filters]);
  };

  const onTextFilter = (text: string) => {
    onFilter([{ label: '', value: text }], 'textFilter');
  };

  const onBlockchainFilter = (values: MultiValue<SelectOption>) => {
    onFilter(values, 'blockchain');
  };

  const onCollectionFilter = (values: MultiValue<SelectOption>) => {
    onFilter(values, 'collection');
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPagination((prevState) => ({
      ...prevState,
      pageIndex: selected,
    }));
  };

  const handlePageSizeChange = (value: SingleValue<SelectOption>) => {
    if (value?.value) {
      setPagination((prevState) => ({
        ...prevState,
        pageIndex: 0,
        pageSize: +value.value,
      }));
    }
  };

  useEffect(() => {
    const hasFilters = filters.some((f) => f.values.length > 0);
    const paginationSettings = {
      from: filteredData.length === 0 ? 0 : pagination.pageIndex * pagination.pageSize + 1,
      to: Math.min((pagination.pageIndex + 1) * pagination.pageSize, filteredData.length),
      of: hasFilters ? filteredData.length : data.length,
      total: data.length,
    };
    setSummaryText(
      `Showing ${paginationSettings.from} to ${paginationSettings.to} of ${paginationSettings.of} ${
        hasFilters ? `entries of the recent ${paginationSettings.total} transactions` : 'recent transactions'
      }`
    );
  }, [pagination, data, filteredData]);

  const pageSizeOptions: SelectOption[] = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const onSort = (sortingValue: SortingValue) => {
    setSorting(sortingValue);
  };

  return (
    <div className="transactions-table">
      <Row className="filter-bar ">
        <Col>
          <Row>
            <Col
              xs={12}
              sm={hideBlockchainFilter ? 12 : 6}
              md={hideBlockchainFilter ? 12 : 6}
              lg={hideBlockchainFilter ? 5 : 2}
              className="mb-2"
            >
              <FilterControl onFilter={onTextFilter} />
            </Col>
            {!hideBlockchainFilter && (
              <Col xs={12} sm={6} md={6} lg={3} className="mb-2">
                <MultiSelectComponent
                  placeholder="Select Blockchain(s)…"
                  options={blockchainOptions}
                  onChange={onBlockchainFilter}
                  values={filters.find((filter) => filter.id === 'blockchain')?.values || []}
                />
              </Col>
            )}
            <Col xs={12} sm={5} md={5} lg={3} className="mb-2">
              {Object.keys(collectionOptions).length > 1 && (
                <MultiSelectComponent
                  placeholder="Select Collection(s)…"
                  options={collectionOptions}
                  onChange={onCollectionFilter}
                  values={filters.find((filter) => filter.id === 'collection')?.values || []}
                />
              )}
            </Col>
            <Col xs={9} sm={5} md={5} lg={3} className="mb-2">
              <SortComponent fields={sortingFields} onSort={onSort} />
            </Col>
            <Col xs={3} sm={2} md={2} lg={1} className="mb-2">
              <SelectComponent
                options={pageSizeOptions}
                onChange={handlePageSizeChange}
                value={{ value: pagination.pageSize.toString(), label: pagination.pageSize.toString() }}
                color={SelectColors.Blue}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {pagedData?.length ? (
        pagedData.map((row) => <TransactionListItemComponent transactionView={row} key={row.txId} />)
      ) : (
        <NoCollectiblesComponent noCollectiblesType={NoCollectiblesType.Transactions} />
      )}

      <Row className="mt-3">
        <Col xs={12} md={6}>
          <div className="page-counter">{summaryText}</div>
        </Col>
        {pagedData?.length > 0 && (
          <Col xs={12} md={6}>
            <ReactPaginate
              className="pagination-selector"
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={Math.ceil(filteredData.length / pagination.pageSize)}
              previousLabel="<"
              forcePage={pagination.pageIndex}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

TransactionListComponent.defaultProps = {
  hideBlockchainFilter: false,
};
