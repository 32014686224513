import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Blockchain from '../../enums/blockchain-enum';
import './deposit-modal-1-component.scss';

interface IDepositModal1Props {
  closeDepositModal1: MouseEventHandler<HTMLButtonElement>;
  depositModal1Visibility: boolean;
  depositLoading: boolean;
  gasMinimumRequired: number | undefined;
  openDepositModal2: (blockchain: Blockchain.Counterparty | Blockchain.Ethereum) => void;
  returnToWithdrawModal2: () => void;
}

export default function DepositModal1(props: IDepositModal1Props): JSX.Element {
  const [isOpenState, setOpenState] = useState(false);
  const {
    closeDepositModal1,
    depositModal1Visibility,
    depositLoading,
    gasMinimumRequired,
    openDepositModal2,
    returnToWithdrawModal2,
  } = props;

  useEffect(() => {
    setOpenState(depositModal1Visibility);
  }, [depositModal1Visibility]);

  return (
    <Modal id="deposit-modal-1" show={isOpenState} onHide={() => closeDepositModal1} animation={false} centered>
      <Modal.Header>
        <Modal.Title>Deposit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>In order to pay your withdrawal fees, you need BCYM (BitCrystals on First Oasis).</p>
        <p>
          If you do not have enough BCYM, you can deposit BCY or BCYETH (BitCrystals on Counterparty, or Ethereum) on
          one of your deposit addresses and they will be converted in BCYM.
        </p>
        <p>Minimal amount to be deposited:</p>
        <h5>{gasMinimumRequired} BitCrystals</h5>
        {!depositLoading ? (
          <div id="buttons">
            <Button onClick={() => openDepositModal2(Blockchain.Counterparty)}>Deposit BCY</Button>
            <Button onClick={() => openDepositModal2(Blockchain.Ethereum)}>Deposit BCYETH</Button>
            <Button href="https://crystalsuite.com/store/bitcrystals-xcp/ " target="_blank">
              I don&apos;t have any BitCrystals
            </Button>
          </div>
        ) : (
          <div id="spinner-container">
            <Spinner animation="grow" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => returnToWithdrawModal2()}>
          Back
        </Button>
        <Button variant="secondary" onClick={closeDepositModal1}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
