import React from 'react';
import { CTooltip } from '@coreui/react';
import { ShortTextConfig } from '../../models/short-text-config';
import CopyButtonControl from '../copy-button/copy-button-control';
import { shortenText } from '../../helpers/tools';
import useWindowDimensions from '../../hooks/use-window-dimensions';

export default function ShortTextControl({
  text,
  numberOfChars,
  description,
  fullDisplay,
  textAlignClass,
  theme,
  tooltipPlacement,
  maxAdaptiveWidthPercentage,
  hideCopyButton,
}: ShortTextConfig): JSX.Element {
  const { width } = useWindowDimensions();

  const getNumberOfChars = (): number => {
    if (width && maxAdaptiveWidthPercentage) {
      const maxTextWidth = Math.floor((width * maxAdaptiveWidthPercentage) / 100);
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (context) {
        context.font = getComputedStyle(document.body).font;
        const textLengthInPixels = context.measureText(text).width;
        const letterWidth = Math.ceil(maxTextWidth / text.length);
        return Math.ceil((text.length - (textLengthInPixels - maxTextWidth) / letterWidth) / 4);
      }
    }

    return text.length;
  };

  return (
    <div className={`d-flex align-items-center ${textAlignClass || 'justify-content-start'}`}>
      <CTooltip content={description || text} placement={tooltipPlacement ?? 'auto'}>
        <span>
          {fullDisplay ? text : shortenText(text, maxAdaptiveWidthPercentage ? getNumberOfChars() : numberOfChars)}
        </span>
      </CTooltip>
      {!hideCopyButton && <CopyButtonControl value={text} theme={theme} />}
    </div>
  );
}
