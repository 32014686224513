import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import './copy-button-control.scss';

export type CopyButtonControlProps = {
  value: string;
  theme?: 'Dark' | 'Light';
  buttonClass?: string;
};

export default function CopyButtonControl({ value, theme, buttonClass }: CopyButtonControlProps): JSX.Element {
  return (
    <Button
      className={`d-inline copy-button ${buttonClass} ${theme === 'Dark' ? 'dark-theme' : 'light-theme'}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {/* This is a hack for mobile version. On mobile navigator.clipboard doesn't work that's why we are using CopyToClipboard */}
      <CopyToClipboard
        text={value}
        onCopy={() => {
          toast('Copied to clipboard');
        }}
      >
        <i className="icon-copy4" />
      </CopyToClipboard>
    </Button>
  );
}

CopyButtonControl.defaultProps = {
  theme: 'Dark',
  buttonClass: 'ms-2',
};
