import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function BalancePlaceholder(): JSX.Element {
  return (
    <>
      <Row>
        <Col xs="12" md="6" className="placeholder-glow mb-3">
          <div className="placeholder rounded w-100" style={{ height: 36 }} />
        </Col>
        <Col xs="12" md="6" className="placeholder-glow mb-3">
          <div className="placeholder rounded w-100" style={{ height: 36 }} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="placeholder-glow mb-3">
          <div className="placeholder rounded w-100" style={{ height: 163 }} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="6" md="4" lg="3" className="placeholder-glow mb-3">
          <div className="placeholder rounded w-100" style={{ height: 375 }} />
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="placeholder-glow mb-3">
          <div className="placeholder rounded w-100" style={{ height: 375 }} />
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="placeholder-glow">
          <div className="placeholder rounded w-100" style={{ height: 375 }} />
        </Col>
        <Col xs="12" sm="6" md="4" lg="3" className="placeholder-glow">
          <div className="placeholder rounded w-100" style={{ height: 375 }} />
        </Col>
      </Row>
    </>
  );
}
