/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TransactionType from '../../enums/transaction-type';
import TransactionItem from '../../models/transaction-item-model';
import LazyCardImageComponent from '../lazy-card-image/lazy-card-image-component';
import './transaction-list-item-component.scss';
import useMediaQuery from '../../hooks/use-media-query';
import MediaQuery from '../../models/media-query';
import ShortTextControl from '../../controls/short-text/short-text-control';

interface TransactionListItemComponentProps {
  transactionView: TransactionItem;
}

interface AccountBadgeProps {
  account: string;
  userName: string;
}

function AccountBadge({ account, userName }: AccountBadgeProps): JSX.Element {
  const navigate = useNavigate();

  const navigateToWallet = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    navigate(`/wallet/${account}`);
  };

  return (
    <div>
      <Badge bg="light" onClick={(e) => navigateToWallet(e)}>
        <ShortTextControl text={account} description={account} fullDisplay={false} theme="Dark" numberOfChars={8} />
      </Badge>
      {userName && <span className="text-black-50">({userName})</span>}
    </div>
  );
}

export default function TransactionListItemComponent({
  transactionView,
}: TransactionListItemComponentProps): JSX.Element {
  const navigate = useNavigate();
  const maxMd = useMediaQuery(MediaQuery.maxMd);
  const maxSm = useMediaQuery(MediaQuery.maxSm);
  const maxXs = useMediaQuery(MediaQuery.maxXs);

  return (
    <Card
      className="transaction-list-item w-100 my-4"
      onClick={() => navigate(`/transactions/${transactionView.txId}`)}
    >
      <LazyCardImageComponent imageUrl={transactionView.image} name={transactionView.contract} usePlaceholder />
      <div>
        <Row className="w-100 mx-0 py-2">
          <Col xs={12} md={6} lg={3} className="px-2">
            {transactionView.collection}
          </Col>
          <Col xs={12} md={6} lg={3} className="px-2">
            <ShortTextControl
              text={transactionView.contract}
              description={transactionView.contract}
              theme="Dark"
              numberOfChars={10}
              textAlignClass="justify-content-center"
            />
          </Col>
          <Col xs={12} md={6} lg={3} className="px-2 text-capitalize">
            <i className="blockchain-image" id={transactionView.blockchain} />
            {transactionView.blockchain}
          </Col>
          <Col xs={12} md={6} lg={3} className="px-2">
            {transactionView.time}
          </Col>
        </Row>
        <hr className="m-0" />
        <div className="d-flex flex-column flex-md-row">
          <AccountBadge account={transactionView.source} userName={transactionView.sourceUsername} />
          <div className="mx-4">
            <span>
              {(transactionView.transactionType === TransactionType.Credit ? '+' : '-') + transactionView.quantity}
            </span>
            {transactionView.transactionType === TransactionType.Credit ? (
              <>
                <i className="icon-arrow-down7 d-md-none" />
                <i className="icon-arrow-right7 d-none d-md-block" />
              </>
            ) : (
              <>
                <i className="icon-arrow-up7 d-md-none" />
                <i className="icon-arrow-left7 d-none d-md-block" />
              </>
            )}
          </div>
          <AccountBadge account={transactionView.destination} userName={transactionView.destinationUsername} />
        </div>
        <hr className="m-0" />
        <div>
          <ShortTextControl
            text={transactionView.txId}
            description={transactionView.txId}
            // eslint-disable-next-line no-nested-ternary
            numberOfChars={maxSm || maxXs ? 10 : maxMd ? 25 : 80}
          />
        </div>
      </div>
    </Card>
  );
}
