import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Collection } from '../../models/collection-model';
import SortComponent, { SortingValue } from '../sort/sort-component';
import './collections-component.scss';
import ClickableCardComponent from '../clickable-card/clickable-card-component';
import wizardImg from '../../assets/images/cheeze-wizard.png';
import LoaderControl from '../../controls/loader/loader-control';
import { getCollectionsAsync } from '../../services/api-service';
import { sortArray } from '../../helpers/tools';

export default function CollectionsComponent(): JSX.Element {
  const [collection, setCollection] = useState({} as Collection[]);
  const [collectionsLoading, setCollectionsLoading] = useState(true);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getCollectionsAsync(cancelToken).then((collectionsResponse) => {
      setCollection(collectionsResponse?.data);
      setCollectionsLoading(false);
    });

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const onCollectionNameSort = (value: SortingValue) => {
    setCollection(sortArray([...collection], 'name', value.sorting));
  };

  return (
    <div id="page-collections">
      <h1>Collections</h1>
      <div>
        {collectionsLoading ? (
          <LoaderControl />
        ) : (
          collection.length && (
            <>
              <div className="row my-4">
                <Col xs={12} md={6} lg={3}>
                  <SortComponent onSort={onCollectionNameSort} caption="Name" />
                </Col>
              </div>

              <div className="d-flex flex-wrap tab-pane fade active show mt-3" id="allCollections-tab">
                <Row sm={1} md={2} lg={3} xl={4} className="g-4">
                  {collection.map((x) => {
                    const title = x.name || 'Collection name';
                    const text = x.description || 'No description provided';
                    const truncatedText = text.length >= 100 ? `${text.substring(0, 100)}...` : text;
                    const image = x.image || wizardImg;
                    const id = x.id || 'eSog';
                    const link = `/collection/${id}`;
                    return (
                      <Col className="d-flex align-items-stretch" key={`collection-${id}`}>
                        <ClickableCardComponent
                          link={link}
                          image={image}
                          title={title}
                          text={truncatedText}
                          isInternalRouting
                          buttonText="View collection"
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
}
